<template>
    <div class="property-seal-component" :class="`seal-type-${type}`">
        <img v-if="type != 'situacao' && blacknovember == false && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == '5 estrelas'" alt="Selo Ouro" src="@/assets/img/feirao-apolar-selo-ouro.webp" width="1" height="1"/>
        <img v-if="type != 'situacao' && blacknovember == false && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == '4 estrelas'" alt="Selo Prata" src="@/assets/img/feirao-apolar-selo-prata.webp" width="1" height="1"/>
        <img v-if="type != 'situacao' && blacknovember == false && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == '3 estrelas'" alt="Selo Bronze" src="@/assets/img/feirao-apolar-selo-bronze.webp" width="1" height="1"/>
        <img v-if="type != 'situacao' && blacknovember == false && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == 'super desconto'" alt="Super Desconto" class="superdesconto" src="@/assets/img/feirao-apolar-selo-superdesconto.webp" width="1" height="1"/>

        <img class="blacknovember-seal" v-if="type != 'situacao' && blacknovember && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == '5 estrelas'" alt="Selo Ouro" src="@/assets/img/blackweek/black-week-5-estrelas.webp" width="1" height="1"/>
        <img class="blacknovember-seal" v-if="type != 'situacao' && blacknovember && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == '4 estrelas'" alt="Selo Prata" src="@/assets/img/blackweek/black-week-4-estrelas.webp" width="1" height="1"/>
        <img class="blacknovember-seal" v-if="type != 'situacao' && blacknovember && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == '3 estrelas'" alt="Selo Bronze" src="@/assets/img/blackweek/black-week-3-estrelas.webp" width="1" height="1"/>
        <img  v-if="type != 'situacao' && blacknovember && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == 'super desconto'" alt="Super Desconto" class="superdesconto blacknovember-seal" src="@/assets/img/blackweek/black-week-super-desconto.webp" width="1" height="1"/>

        <img class="blacknovember-seal blacknovember-footer" v-if="type != 'situacao' && blacknovember && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() != 'super desconto'" alt="Selo Ouro" src="@/assets/img/blackweek/black-week-default-rodape.webp" width="1" height="1"/>
        <img  v-if="type != 'situacao' && blacknovember && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == 'super desconto'" alt="Super Desconto" class="superdesconto blacknovember-seal blacknovember-footer" src="@/assets/img/blackweek/black-week-super-desconto-rodape.webp" width="1" height="1"/>

        <img v-if="type == 'situacao' && property.situacao.toLowerCase() == 'locado'" alt="Imóvel Locado" src="@/assets/img/rented-stamp.webp"/>
        <img v-if="type == 'situacao' && property.situacao.toLowerCase() == 'vendido'" alt="Imóvel Vendido" src="@/assets/img/stamp-sold.webp"/>
    </div>
</template>
<script>

export default {
  components: {
  },
  props: {
    type: {
      type: String,
      default() {
        return "feirao"//feirao/situacao
      }
    },
    property: {
      type: Object,
      default() {
        return {}
      }
    },
    blacknovember: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  computed: {
  },
  data() {
    return {
    
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>
<style>
</style>
