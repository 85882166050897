<template>
  <div class="page-lancamentos-new-home">

    <homeSearchVue/>

    <bannerHomeActionVue/>
    
    <section class="lancamentos-properties">
        <div class="container">

            <div class="lancamentos-properties-header">
              <h3>Nomes dos empreendimentos</h3>

              <a href="#">Ver todos os lançamentos</a>
            </div>
            <div class="row">
                <template v-for="property,key in properties">
                    <Immobile 
                        v-bind:key="key" 
                        v-bind:property="property">
                    </Immobile>
                </template>
            </div>

            <button class="load-more" v-if="scroll_id != '' && total > properties.length" v-on:click="loadMore" >Carregar mais imóveis</button>
        </div>
    </section>

    <bannerHomeInvistaActionVue/>

    <section class="box-properties-section lancamento-properties-section">
      <div class="container">
        <PropertiesCarousel
          :link_label="__('Visitar a página de Lançamentos Apolar')"
          :link_url="'/lancamentos'"
          :sub_business="'lancamentos'"
        >
          <template v-slot:title>
            {{__('Lançamentos')}}
          </template>

          <template v-slot:subtitle>
          </template>

          <template v-slot:footer>
            
          </template>
        </PropertiesCarousel>
      </div>
    </section>

    <section class="lancamentos-footer-banners">

      <carousel class="site-slider" :items=1 :loop=false :autoplay=false :nav=false :dots=false :autoHeight=true>

        <div class="slider-item-area did-not-find open-form-lancamento">
            <img
              src="@/assets/img/lancamentos/banner-ainda-nao-achou.webp"
              alt="Apolar Procura"
              class="bg"
            />
            <div class="container">
              <div class="title">Ainda não achou o imóvel ideal?</div>
              <div class="description">Conheça o Apolar Procura,<br/>A ferramenta que combina para você!</div>
              <div class="foot">
                <div class="button-more">Saiba mais</div>
                <img src="@/assets/img/lancamentos/procura-logo-white.webp" alt="Apolar Procura">
              </div>
            </div>
        </div>
      </carousel>

      
    </section>

    <FormLancamentos 
      :immobile_name="form_lancamentos.immobile_name" 
      :open.sync="form_lancamentos.open" />

  </div>
</template>
<script>

import "@/assets/scss/custom/lancamentos/_home.scss"

import Vue from "vue";
import Immobile from "../components/apolar/immobile";
import carousel from 'vue-owl-carousel'
import FormLancamentos from "../components/apolar/form-lancamentos.vue";
import homeSearchVue from '../components/apolar/lancamentos/home-search.vue';
import bannerHomeActionVue from '../components/apolar/lancamentos/banner-home-action.vue';
import bannerHomeInvistaActionVue from '../components/apolar/lancamentos/banner-home-invista-action.vue';
import PropertiesCarousel from "../components/apolar/principal/properties-carousel.vue"
import PropertiesService from "../../services/PropertiesService";




export default {
  name: "lancamentos",
  components: {
    carousel,
    Immobile,
    FormLancamentos,
    homeSearchVue,
    bannerHomeActionVue,
    bannerHomeInvistaActionVue,
    PropertiesCarousel
  },
  metaInfo() {
    return {
      title: "Lançamentos",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:"Apolar Lançamentos"
        }
      ]
    };
  },
  data() {
    return {
      locality_selected: "todas",
      localities_options: [
        { value: "todas", label: "Todas as Cidades" },
        { value: "curitiba", label: "Curitiba" },
        { value: "grande-curitiba", label: "Grande Curitiba" },
        { value: "litoral", label: "Litoral" },
        { value: "parana-santa-catarina", label: "Paraná/Santa Catarina" }
      ],
      properties: [],
      scroll_id: '',
      total:0,
      form_lancamentos: {
        open: false,
        immobile_name:''
      },
      layout_top_slider: [
        {"slides":['alphaville','lemonde','reserva'],'weeks': [37,38,41,44,47,50,53,56]},
        {"slides":['lemonde','reserva','alphaville'],'weeks': [39,42,45,48,51,54,57]},
        {"slides":['reserva','alphaville','lemonde'],'weeks': [40,43,46,49,52,55,58]}
      ]
    };
  },
  computed: {

    getLayoutSlide(){
      let weekNumber = new Date().getWeekNumber()
      let slides = this.layout_top_slider[0]['slides']

      this.layout_top_slider.forEach((slideTemplate)=>{
        if ( slideTemplate['weeks'].indexOf(weekNumber) != -1 ) {
          slides = slideTemplate['slides']
        }
      })
      
      return slides
    },

    locality_selected_label: function () {
      let list = this.localities_options.filter(
        (opt) => opt.value == this.locality_selected
      );
      return list.length > 0 ? list[0].label : "";
    },

  },
  methods: {
    openLocalityOptions: function () {
      if (
        document
          .querySelector(".locality_selected")
          .classList.contains("opened")
      ) {
        document.querySelector(".locality_selected").classList.remove("opened");
        document.querySelector("ul.filter-localities-options").classList.remove("opened");
      } else {
        document.querySelector(".locality_selected").classList.add("opened");
        document.querySelector("ul.filter-localities-options").classList.add("opened");
      }
    },
    changeLocality: function (locality) {
      this.locality_selected = locality;
      document.querySelector(".locality_selected").classList.remove("opened");
      document.querySelector("ul.filter-localities-options").classList.remove("opened");

      let home_filter = locality != "todas" ? {"city": locality} : {}
      this.search(home_filter)
    },
    search(filters, append = false) {
      PropertiesService.customSearch(filters)
      .then(
        response => {
            var properties = response.data
            if (append) {
              properties = this.properties.concat(response.data)
            }
            Vue.set(this, "properties", properties)
            Vue.set(this, "scroll_id", response.scroll_id)
            Vue.set(this, 'total', response.total)
        }
      )
    },
    loadMore() {
      let next_page = {"scroll_id": this.scroll_id}
      this.search(next_page, true)
    },
    openFormLancamentos(immobile_name) {
      this.form_lancamentos.immobile_name = immobile_name
      this.form_lancamentos.open = true
    }
  },
  mounted() {
    this.search({})

    // PropertiesService.getFilters()
    // .then(
    //     response => {
    //         let localities_options = response.data.cities.map(item => {return {value:item.city, label:item.city}})
    //         localities_options.unshift({ value: "todas", label: "Todas as Cidades" })
    //         Vue.set(this, "localities_options", localities_options.slice(0, 5))
    //     }
    // )
  }
};
</script>
