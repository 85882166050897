<template>
    <div class="page-principal-faq">
        <div class="container">
            <h1>{{ __('Política de Cookies') }}</h1>

            <div class="row">
                <div class="col-sm-12">

                    <div v-if="lang=='ptBR'">
                        <p><span style="font-weight: 400;">Esta política de cookies, tem como objetivo descrever as
                                práticas que seguimos para respeitar a privacidade de todos os visitantes do nosso
                                site.</span></p>
                        <p><span style="font-weight: 400;">Ao acessar o site da Apolar você autoriza que usemos cookies
                                de acordo com a presente Política. Caso não concorde, você pode configurar seu navegador
                                para recusar cookies, mas consequentemente poderá não conseguir acessar ou usar partes
                                do site.</span></p>
                        <h4><b>O que são cookies?</b></h4>
                        <p><span style="font-weight: 400;">Cookies são arquivos ou informações que podem ser armazenadas
                                em seus dispositivos quando você visita o site ou utiliza canais digitais da Apolar.
                                Servem para identificá-lo, lembrar de suas preferências e facilitar a administração
                                efetiva do website. Permite também personalizar a navegação de acordo com o seu perfil,
                                tornando a utilização de nossas plataformas cada vez mais agradável.</span></p>
                        <h4><b>Como a Apolar usa os cookies?</b></h4>
                        <p><span style="font-weight: 400;">Nós usamos cookies para:</span></p>
                        <ul>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Correto
                                    funcionamento do site</span></li>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Personalizar a
                                    navegação de acordo com seu perfil, proporcionando melhores experiências a
                                    você</span></li>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Acessar a sua
                                    conta na Área do Cliente&nbsp;</span></li>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Auxiliar você
                                    no preenchimento de formulários</span></li>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Realizar
                                    métricas de engajamento das plataformas, campanhas e promoções da Apolar
                                    Imóveis</span></li>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Dispor a você
                                    publicidade direcionada ao seu interesse</span></li>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Aprimorar os
                                    serviços e/ou funcionalidades do site e das plataformas da Apolar Imóveis</span>
                            </li>
                        </ul>
                        <h4><b>Cookies utilizados em nosso site</b></h4>
                        <p><span style="text-decoration: underline;"><strong>Cookies obrigatórios:</strong></span> <span
                                style="font-weight: 400;">São essenciais para que os websites da Apolar carreguem
                                adequadamente. Você pode configurar seu computador para bloquear ou alertar sobre a
                                coleta desses cookies, mas isso poderá comprometer sua navegação.</span></p>
                        <p><span style="text-decoration: underline;"><b>Cookies de desempenho:</b></span><span
                                style="font-weight: 400;"> Nos ajudam a entender como os visitantes interagem com as
                                páginas da Apolar. Eles nos ajudam a saber o número de visitas e quais são as páginas
                                mais e menos populares. Todas as informações coletadas são anônimas e em caso de
                                desabilitação, sua navegação não será registrada.</span></p>
                        <p><span style="text-decoration: underline;"><b>Cookies de funcionalidade:</b></span><span
                                style="font-weight: 400;"> Permitem que as páginas da Apolar Imóveis se lembrem de suas
                                escolhas, proporcionando uma melhor experiência. A ausência de coleta desses dados pode
                                tornar a experiência no website menos funcional.</span></p>
                        <p><span style="text-decoration: underline;"><b>Cookies de publicidade:</b></span><span
                                style="font-weight: 400;"> São utilizados para fornecer conteúdo mais relevante para
                                você, entregando anúncios de acordo com seu interesse. Se não permitir esses cookies,
                                você receberá menos publicidade de nossas novidades selecionadas de acordo com seu
                                perfil.</span></p>
                        <p><span style="text-decoration: underline;"><b>Cookies de redes sociais: </b></span><span
                                style="font-weight: 400;">São estabelecidos por serviços de redes sociais,
                                possibilitando o compartilhamento de conteúdo para mídias externas.</span></p>
                        <h4><b>Como excluir e bloquear cookies?</b></h4>
                        <p><span style="font-weight: 400;">Alguns navegadores aceitam cookies automaticamente, mas você
                                pode alterar as configurações de seu navegador para recusar cookies acessando o recurso
                                de Ajuda na barra de ferramenta de seu navegador.</span></p>
                        <p><span style="font-weight: 400;">Para gerenciar os cookies no aplicativo:</span></p>
                        <p><span style="font-weight: 400;">Se o seu dispositivo for Android abra o aplicativo do Google
                                Chrome em seu smartphone, vá na aba de “Menu” selecionando os três pontinhos do canto
                                superior direito. Abrindo esta aba, selecione a opção “Configurações”, role para baixo
                                até encontrar a opção “Configurações do site” e selecione-a. Clicando na opção “Cookies”
                                você poderá ativar ou desativar a opção de Cookies, também caso você queira que o
                                navegador faça o bloqueio de cookies de terceiros, é só marcar esta opção.</span></p>
                        <p><span style="font-weight: 400;">Sendo o seu dispositivo iOS, abra o aplicativo “Ajustes” em
                                seu iPhone, role até encontrar o “Safari” e clique nele. Vá até a opção “Privacidade e
                                segurança” e ative ou desative “Bloquear todos os cookies”.</span></p>
                        <p><span style="font-weight: 400;">Importante alertar que ao desabilitar os cookies que
                                utilizamos, pode impactar sua experiência em nosso site, por exemplo, você talvez não
                                possa visitar certas áreas de uma página do nosso site ou talvez não receba informações
                                personalizadas quando visitar uma página do nosso site.</span></p>
                        <p><span style="font-weight: 400;">Caso você use dispositivos diferentes para visualizar e
                                acessar o nosso site (por exemplo, computador, smartphone, tablet, etc.) deve
                                assegurar-se de que cada navegador de cada dispositivo está ajustado para atender suas
                                preferências quanto aos cookies.</span></p>
                        <h4><b>Mudanças na Política de Cookies</b></h4>
                        <p><span style="font-weight: 400;">A Apolar reserva-se no direito de alterar esta Política de
                                Cookies a qualquer momento, então antes de usar nossos serviços, consulte o portal.
                                Lembre-se: ao continuar a acessar a plataforma depois que tais alterações passarem a
                                valer, você estará concordando em estar vinculado à nova versão da Política.</span></p>
                        <h4><b>Contato</b></h4>
                        <p><span style="font-weight: 400;">Caso precise de qualquer suporte ou tenha alguma dúvida,
                                pedido ou sugestão em relação a essa Política, por favor entre em contato através do
                                e-mail </span><a href="mailto:lgpdcontato@apolar.com.br"><span
                                    style="font-weight: 400;">lgpdcontato@apolar.com.br</span></a></p>
                    </div>

                    <div v-if="lang=='es'" >
                        <p>
                            <span style="font-weight: 400;">
                                Esta política de cookies tiene como objetivo describir las prácticas que seguimos para respetar la privacidad de todos los visitantes de nuestro sitio web.
                            </span>
                        </p>

                        <p>
                            <span style="font-weight: 400;">
                                Al acceder al sitio de Apolar, usted autoriza que usemos cookies de acuerdo con esta Política. Si no está de acuerdo, puede configurar su navegador para rechazar cookies, pero, como consecuencia, podría no conseguir acceder o usar partes del sitio.
                            </span>
                        </p>
                        
                        <h4><b>¿Qué son cookies?</b></h4>
                        <p>
                            <span style="font-weight: 400;">
                                Los cookies son archivos o información que pueden almacenarse en sus dispositivos cuando visita el sitio o utiliza canales digitales de Apolar. Sirven para identificarlo, recordar sus preferencias y facilitar la administración efectiva del sitio web. También permiten personalizar la navegación según su perfil, haciendo que el uso de nuestras plataformas sea cada vez más agradable.
                            </span>
                        </p>

                        <h4><b>¿Cómo usa Apolar los cookies?</b></h4>
                        
                        <p>
                            <span style="font-weight: 400;">
                                Usamos cookies para:
                            </span>
                        </p>

                        <ul>
                            <li>El correcto funcionamiento del sitio</li>
                            <li>Personalizar la navegación de acuerdo con su perfil, proporcionando mejores experiencias a usted</li>
                            <li>Acceder a su cuenta en el Área del Cliente</li>
                            <li>Ayudarle en el llenado de formularios</li>
                            <li>Realizar métricas de compromiso de las plataformas, campañas y promociones de Apolar Inmuebles</li>
                            <li>Ofrecerle publicidad dirigida a sus intereses</li>
                            <li>Mejorar los servicios y/o funcionalidades del sitio y las plataformas de Apolar Inmuebles</li>
                        </ul>

                        <h4><b>Cookies utilizados en nuestro sitio</b></h4>

                        <p>
                            <span style="text-decoration: underline;"><strong>Cookies obligatorios:</strong></span> 
                            <span style="font-weight: 400;">
                                Son esenciales para que los sitios web de Apolar se carguen adecuadamente. Puede configurar su computadora para bloquear o alertar sobre la recopilación de estos cookies, pero esto podría comprometer su navegación.
                            </span>
                        </p>

                        <p>
                            <span style="text-decoration: underline;"><strong>Cookies de rendimiento:</strong></span> 
                            <span style="font-weight: 400;">
                                Nos ayudan a entender cómo los visitantes interactúan con las páginas de Apolar. Nos permiten saber el número de visitas y cuáles son las páginas más y menos populares. Toda la información recopilada es anónima y, en caso de deshabilitación, su navegación no será registrada.
                            </span>
                        </p>

                        <p>
                            <span style="text-decoration: underline;"><strong>Cookies de funcionalidad:</strong></span> 
                            <span style="font-weight: 400;">
                                Permiten que las páginas de Apolar Inmuebles recuerden sus elecciones, proporcionando una mejor experiencia. La ausencia de recopilación de estos datos puede hacer que la experiencia en el sitio web sea menos funcional.
                            </span>
                        </p>

                        <p>
                            <span style="text-decoration: underline;"><strong>Cookies de publicidad:</strong></span> 
                            <span style="font-weight: 400;">
                                Son utilizados para ofrecer contenido más relevante para usted, entregando anuncios de acuerdo con su interés. Si no permite estos cookies, recibirá menos publicidad de nuestras novedades seleccionadas según su perfil.
                            </span>
                        </p>

                        <p>
                            <span style="text-decoration: underline;"><strong>Cookies de redes sociales:</strong></span> 
                            <span style="font-weight: 400;">
                                Son establecidos por servicios de redes sociales, posibilitando el compartir contenido en medios externos.
                            </span>
                        </p>

                         
                        <h4><b>¿Cómo eliminar y bloquear cookies?</b></h4>
                        
                        <p>
                            <span style="font-weight: 400;">
                                Algunos navegadores aceptan cookies automáticamente, pero puede cambiar las configuraciones de su navegador para rechazar cookies accediendo al recurso de Ayuda en la barra de herramientas de su navegador.
                            </span>
                        </p>

                        <p>
                            <span style="font-weight: 400;">
                                Para gestionar los cookies en la aplicación:
                            </span>
                        </p>
                        
                        <p>
                            <span style="text-decoration: underline;"><strong>Si su dispositivo es Android</strong></span> 
                            <span style="font-weight: 400;">
                                abra la aplicación del Google Chrome en su smartphone, vaya a la pestaña de “Menú” seleccionando los tres puntitos en la esquina superior derecha. Al abrir esta pestaña, seleccione la opción “Configuraciones”, desplácese hacia abajo hasta encontrar la opción “Configuraciones del sitio” y selecciónela. Al hacer clic en la opción “Cookies”, podrá activar o desactivar la opción de Cookies. También, si desea que el navegador bloquee cookies de terceros, solo marque esta opción.
                            </span>
                        </p>

                        <p>
                            <span style="text-decoration: underline;"><strong>Si su dispositivo es iOS</strong></span> 
                            <span style="font-weight: 400;">
                                abra la aplicación “Ajustes” en su iPhone, desplácese hasta encontrar “Safari” y haga clic en él. Vaya a la opción “Privacidad y seguridad” y active o desactive “Bloquear todas las cookies”.
                            </span>
                        </p>

                        <p>
                            <span style="font-weight: 400;">
                                Es importante señalar que al deshabilitar los cookies que utilizamos, puede afectar su experiencia en nuestro sitio. Por ejemplo, es posible que no pueda visitar ciertas áreas de una página de nuestro sitio o que no reciba información personalizada cuando visite una página de nuestro sitio.
                            </span>
                        </p>

                        <p>
                            <span style="font-weight: 400;">
                                Si utiliza diferentes dispositivos para ver y acceder a nuestro sitio (por ejemplo, computadora, smartphone, tablet, etc.), debe asegurarse de que cada navegador de cada dispositivo esté configurado para atender sus preferencias respecto a los cookies.
                            </span>
                        </p>

                        <h4><b>Cambios en la Política de Cookies</b></h4>
                        <p>
                            <span style="font-weight: 400;">
                                Apolar se reserva el derecho de cambiar esta Política de Cookies en cualquier momento. Antes de usar nuestros servicios, consulte el portal. Recuerde: al continuar accediendo a la plataforma después de que tales cambios entren en vigor, estará de acuerdo en estar vinculado a la nueva versión de la Política.
                            </span>
                        </p>
                        
                        <h4><b>Contacto</b></h4>
                        <p>
                            <span style="font-weight: 400;">
                                Si necesita cualquier soporte o tiene alguna duda, solicitud o sugerencia en relación con esta Política, por favor, póngase en contacto a través del correo electrónico <a href="mailto:lgpdcontato@apolar.com.br">lgpdcontato@apolar.com.br</a>.
                            </span>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import "@/assets/scss/custom/principal/_faq.scss"
import ContactForm from '../components/apolar/principal/contact-form.vue';

export default {
    name: "faq",
    components: {
        ContactForm
    },
    metaInfo() {
        let title = this.lang == "ptBR" ? "Política de Cookies | Imobiliária em Curitiba - Imóveis à Venda e Locação" : "Política de cookies | Inmobiliaria en Paraguay - Propiedades en Venta y Alquiler";
        let description = this.lang == "ptBR" ? "Consulte as dúvidas frequentes ou nos envie também a suas. Acesse agora mesmo e esclareça suas dúvidas!" : "Consulta las preguntas frecuentes o envíanos también la tuya. ¡Accede ahora y aclara tus dudas!"
        return {
            title: title,
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content: description
                }
            ]
        };
    },
    data() {
        return {

        };
    },
    computed: {


    },
    methods: {

    },
    mounted() {

    }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/custom/principal/_faq.scss"
</style>