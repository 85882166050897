<template>
     <l-map ref="openstreetcomp" class="openstreet-component" :zoom="zoom" :center="center" style="height:300px">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <v-marker-cluster :options="clusterOptions" >
        <l-marker v-for="marker, index in markers" v-bind:key="`map-immobile-${index}`" :lat-lng="marker.latlng">
            <v-popup :content="getMarkerPopupContent(marker)"></v-popup>
        </l-marker>
      </v-marker-cluster>
    </l-map>
</template>
<script>
import Vue from 'vue';
import jQuery from "jquery";
import * as Vue2Leaflet from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import L from 'leaflet';
var { LMap, LTileLayer, LMarker } = Vue2Leaflet;

import 'leaflet/dist/leaflet.css';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('@/assets/img/marker-icon-2x.png'),
  iconUrl: require('@/assets/img/marker-icon-2x.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
        'v-popup': Vue2Leaflet.LPopup,
        'v-marker-cluster': Vue2LeafletMarkerCluster
    },
    props: {
        properties: {
            type: Array,
            default: [],
        },
        refresh: {
            type: Boolean,
        }
    },
    computed:{
    },
    data() {
        return {
            markers: [],
            zoom: 12,
            center: L.latLng(-25.4284,-49.2733),
            url: 'https://mapa.apolar.com.br/tiles/{z}/{x}/{y}.png',
            attribution: 'Apolar Imóveis',
            clusterOptions: {disableClusteringAtZoom: 11},
            filters: {}
        }
    },
    methods: {
        slugify(text) {
            return text
                .toString()                           // Cast to string (optional)
                .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
                .toLowerCase()                  // Convert the string to lowercase letters
                .trim()                                  // Remove whitespace from both sides of a string (optional)
                .replace(/\s+/g, '-')            // Replace spaces with -
                .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
                .replace(/\-\-+/g, '-');        // Replace multiple - with single -
        },
        getMarkerPopupContent(marker) {

            var path = window.location.pathname
            var cidade = this.slugify(marker.cidade)
            var bairro = this.slugify(marker.bairro)
            var condominio = this.slugify(typeof marker.condominio != 'undefined' && marker.condominio != null ? marker.condominio : 'imovel')
            path = path.replace('busca', `imovel/${cidade}/${bairro}/${cidade}-${bairro}-${condominio}-${marker.referencia}`)

            return `
                <div class="map-immobile">
                    <img style="width: 100px" src="${marker.foto_principal}">
                    <div class="condominio">${typeof marker.condominio != 'undefined' && marker.condominio != null ? marker.condominio : 'Imóvel'}</div>
                    <div class="endereco">${marker.rua_e_numero}</div>
                    <div class="bairro_cidade">${marker.bairro} - ${marker.cidade}</div>
                    <a href="${path}" target="_blank">Ver Imóvel</a>
                </div>
            `
        },
        setCenterByFilter: function () {
            var address = this.filters.city + ',Brazil'
            var location = null
            this.getPositionByName(address, (response) => {
                this.map.setView(new L.LatLng(response[1], response[0]), 12);
            })
            return location;
        },
        getPositionByName: function (location, callback) {

            jQuery.ajax({
                url: 'https://www.apolar.com.br/wp-admin/admin-ajax.php?action=geocodingsaved&location='+location,
                type:'GET',
                async: false,
                dataType: 'json',
                success: (response) => {
                    if ( response ) {
                        callback(response)
                    } else {
                        jQuery.ajax({
                            url: 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + location + '.json?access_token=' + mapboxgl.accessToken,
                            type: 'GET',
                            success: (response) => {
                                console.log('Response Geocoding: ', response)
            
                                var center = null
                                jQuery.each(response.features, (i, v) => {
                                    if (v.id.indexOf('place') !== -1) {
                                        center = v.center
                                        return true;
                                    }
                                })
            
                                if (center == null) {
                                    center = response.features[0].center
                                }
            
                                jQuery.ajax({
                                    url: 'https://www.apolar.com.br/wp-admin/admin-ajax.php?action=geocodingsaved&location='+location+'&coordinates=' + center.join(','),
                                    type:'GET',
                                    dataType: 'json',
                                    success: (response) => {
                                        console.log('geocoding persist')
                                    }
                                })
            
                                callback(center)
                            }
                        })
                    }
                }
            })

        }
    },
    mounted() {
    },
    watch: {
        properties: function(properties) {
            
            function preparePro(prop) {
                prop.latlng = L.latLng(prop.EixoX,prop.EixoY)
                return prop
            }

            var props = properties.map(prop => {return preparePro(prop)})
            this.markers = props

            if ( props.length == 1 ) {
                Vue.set(this, 'center', props[0].latlng)
            }
            
            Vue.set(this, 'filters', this.$advanced_filters)
        },

        refresh: function() {
            this.$refs.openstreetcomp.mapObject.invalidateSize()
        }
    }
};
</script>
<style>
</style>