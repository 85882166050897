import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import LancamentosHeader from "./layout/LancamentosHeader";
import DefaultHeader from "./layout/DefaultHeader";
import PrincipalHeader from "./layout/PrincipalHeader";
import HeaderIndique from "./layout/HeaderIndique.vue";
import AppFooter from "./layout/AppFooter";
import PrincipalFooter from "./layout/PrincipalFooter";
import Components from "./views/Components.vue";
import Landing from "./views/Landing.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import Profile from "./views/Profile.vue";

import Lancamentos from "./views/lancamentos/home.vue";
import LancamentosPropertyDetails from "./views/lancamentos/propertyDetails.vue";
import LancamentosSearch from "./views/LancamentosSearch.vue";
import LancamentosNewSearch from "./views/lancamentos/propertyNewSearch.vue"

import HomePrincipal from "./views/principal/home.vue"
import PropertyDetailsPrincipal from "./views/principal/propertyDetails.vue";
import PrincipalSearch from "./views/principal/propertySearch.vue"
import PrincipalNewSearch from "./views/principal/propertyNewSearch.vue"
import FaqPrincipal from "./views/principal/faq.vue"
import IndiquePrincipal from "./views/principal/indique.vue"
import ContactPrincipal from "./views/principal/contact.vue"
import workWithUs from "./views/principal/work-with-us.vue";
import FranchisePrincipal from "./views/principal/franchise.vue"
import InstitutionalPrincipal from "./views/principal/institutional.vue"
import CookiePolicy from "./views/principal/cookie-policy.vue"

import HomeCondominios from "./views/condominios/home.vue"


import PropertiesService from "./services/PropertiesService"
import utils from "./utils";
Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  mode: 'history',
  base: "/",
  routes: [
    {
      path: "/",
      name: "principal",
      components: {
        header: PrincipalHeader,
        //default: HomePrincipal,
        default: () => {
          const queryString = window.location.search;
          const entries = new URLSearchParams(queryString).entries();
          let params = {}

          for(const entry of entries) {
            params[entry[0]] = entry[1]
          }

          if ( typeof params['ref'] != 'undefined' && params['ref'] ) {
            PropertiesService.getByReference(params['ref'], 'main')
            .then((resp)=> {
              if (resp.success) {
                let _url = utils.get_property_url(resp.data)
                let url = new URL(_url)
                delete params['ref']
                url.search = new URLSearchParams(params)
                window.location.href = url.toString()
              }
            })
          } else {
            return import("./views/principal/home.vue")
          }
          
          
        },
        footer: PrincipalFooter
      }
    },
    {
      path: "/lancamentos",
      name: "lancamentos",
      components: {
        header: LancamentosHeader,
        default: Lancamentos,
        footer: PrincipalFooter
      }
    },
    {
      path: "/lancamentos/busca",
      name: "lancamentos-property-search",
      components: {
        header: null,
        default: LancamentosNewSearch,
        footer: PrincipalFooter
      }
    },
    {
      path: "/lancamentos/imovel/:city/:district/:slug",
      name: "property-details",
      components: {
        header: LancamentosHeader,
        default: LancamentosPropertyDetails,
        footer: PrincipalFooter
      }
    },


    //pagina principal - detalhes de imóveis de venda e aluguel
    {
      path: "/principal",
      name: "principal",
      components: {
        header: PrincipalHeader,
        default: HomePrincipal,
        footer: PrincipalFooter
      }
    },

    {
      path: "/faq",
      name: "principal-faq",
      components: {
        header: PrincipalHeader,
        default: FaqPrincipal,
        footer: PrincipalFooter
      }
    },
    {
      path: "/indique",
      name: "principal-indique",
      components: {
        header: HeaderIndique,
        default:IndiquePrincipal,
        footer: PrincipalFooter
      }
    },

    {
      path: "/fale-conosco",
      name: "principal-contact",
      components: {
        header: PrincipalHeader,
        default: ContactPrincipal,
        footer: PrincipalFooter
      }
    },

    {
      path: "/trabalhe-conosco",
      name: "principal-work-us",
      components: {
        header: PrincipalHeader,
        default: workWithUs,
        footer: PrincipalFooter
      }
    },

    {
      path: "/franquias",
      name: "principal-franchise",
      components: {
        header: PrincipalHeader,
        default: FranchisePrincipal,
        footer: PrincipalFooter
      }
    },

    {
      path: "/politica-de-cookies",
      name: "politica-de-cookies",
      components: {
        header: PrincipalHeader,
        default: CookiePolicy,
        footer: PrincipalFooter
      }
    },

    {
      path: "/institucional/:path?",
      name: "principal-institutional",
      components: {
        header: PrincipalHeader,
        default: InstitutionalPrincipal,
        footer: PrincipalFooter
      }
    },

    {
      path: "/condominio/:nomecondominio",
      name: "condominios-home",
      components: {
        header: PrincipalHeader,
        default: HomeCondominios,
        footer: PrincipalFooter
      }
    },

    {//match de slug string seguida de traco e numero
      path: "/:transaction/:city/:district/:slug(.*-\\d+)",
      name: "principal-property-details",
      components: {
        header: PrincipalHeader,
        default: () => {
          let last_param = window.location.href.split('/').slice(-1)[0]
          last_param = last_param.split('?')[0]
          let reference = parseInt(last_param.split('-').slice(-1)[0])
          
          if ( isNaN(reference) ) {
            return import("./views/principal/propertySearch.vue")
          }
          return import("./views/principal/propertyDetails.vue")
        },
        footer: PrincipalFooter
      }
    },


    {//match de slug string seguida de traco e numero
      path: "/:transaction/:city/:slug(.*-\\d+)",
      name: "principal-property-details",
      components: {
        header: PrincipalHeader,
        default: () => {
          let last_param = window.location.href.split('/').slice(-1)[0]
          last_param = last_param.split('?')[0]
          let reference = parseInt(last_param.split('-').slice(-1)[0])
          
          if ( isNaN(reference) ) {
            return import("./views/principal/propertySearch.vue")
          }
          return import("./views/principal/propertyDetails.vue")
        },
        footer: PrincipalFooter
      }
    },

    {
      path: "/:transaction?/:type?/:city?/:district?/:address?/:bedrooms?",
      name: "principal-property-search",
      components: {
        header: null,
        default: PrincipalNewSearch,
        footer: PrincipalFooter
      }
    },


  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
