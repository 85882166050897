<template>
    <div class="contact-form-component">
        <h2>{{__(title)}}</h2>

        <div v-if="send_status == 'sending'" class="send-proccess">
            <bounce-loader :color="'#FFC20F'"></bounce-loader>
        </div>

        <div v-if="send_status == 'sended'" class="send-proccess">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            {{__('Contato enviado!')}}
        </div>

        <div v-if="send_status == ''">
            <div class="form-input" :class="typeof this.errors['name'] != 'undefined' ? 'error': ''">
                <input 
                    type="text" :placeholder="__('Nome completo')" v-model="contact.name">
            </div>

            <div class="form-input" :class="typeof this.errors['email'] != 'undefined' ? 'error': ''">
                <input 
                    type="email" :placeholder="__('E-mail')" v-model="contact.email">
            </div>

            <div class="form-input" :class="typeof this.errors['phone'] != 'undefined' ? 'error': ''">
                <input 
                    v-mask="phone_mask" type="phone" :placeholder="__('Telefone')" v-model="contact.phone">
            </div>

            <div class="form-input" :class="typeof this.errors['message'] != 'undefined' ? 'error': ''">
                <textarea 
                    v-model="contact.message" :placeholder="__('Mensagem')" cols="30" rows="10"></textarea>
            </div>

            <div @click="send" class="send-contact">ENVIAR</div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import "@/assets/scss/custom/principal/components/_contact-form_component.scss"
import * as yup from "yup";
import {mask} from 'vue-the-mask'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import ContactService from "../../../../services/ContactService";

const validateSchema = yup.object({
    name: yup.string().required("Nome completo é obrigatório."),
    phone: yup.string().required("Telefone é obrigatório."),
    email: yup.string().required("E-mail é obrigatório.").email("E-mail inválido."),
    message: yup.string().required("Nos envie sua mensagem.")
})

export default {
    directives: {mask},
  components: {
    BounceLoader
  },
  props: {
    title: {
      type: String,
      default() {
        return "Procurando algo a mais entre em contato"
      }
    },
    send_to: {
      type: String,
      default() {
        return "apolar@apolar.com.br"
      }
    },
  },
  data() {
    return {
        errors: {},
        contact: {
            name: '',
            email: '',
            phone: '',
            message: ''
        },
        send_status: ''
    };
  },
  computed: {
      prepare_search() {
      },

      phone_mask() {
        let mask = ['(##) #####-####']
        if (this.lang == 'es') {
            mask = ['##############']
        }
        return mask
    }
  },
  methods: {
    send() {

        this.errors = {}

        validateSchema
            .validate(this.contact, {abortEarly: false})
            .then(async ()=>{

                this.send_status = "sending"

                let send_to = this.lang == 'ptBR' ? 'apolar@apolar.com.br' : 'joao.geron@apolar.com.py'

                let data_contact = {
                    "subject": `Contato do Site Fale Conosco - Enviado por ${this.contact.name}`,
                    "to": send_to,
                    "fields": [
                        {
                            "label": "Nome Completo",
                            "value": this.contact.name,
                        },
                        {
                            "label": "Telefone",
                            "value": this.contact.phone,
                        },
                        {
                            "label": "E-mail",
                            "value": this.contact.email,
                        },
                        {
                            "label": "Mensagem",
                            "value": this.contact.message,
                        }
                    ],
                    "_recaptcha_token": await this.createRecaptchaToken('CONTACT')
                }

                ContactService.sendGenericContact(data_contact)
                .then((reponse)=>{
                    this.send_status = "sended"
                })
                .catch((error)=>{
                    this.send_fail = true
                })
                .finally(()=>{
                    
                    this.send_button = "ENVIAR"

                    setTimeout(()=>{
                        
                        Object.keys(this.contact).forEach((field)=>{
                            this.contact[field] = ''
                        })

                        this.send_status = ""

                    },4000)
                })
                
                
            })
            .catch(err => {
                if ( typeof err.inner != "undefined" ) {
                    err.inner.forEach(error => {
                        Vue.set(this.errors, error.path, error.message)
                    });
                }
            })

    }
  },
  mounted() {
    this.loadRecaptchaTag()
  }
}
</script>
<style>
</style>
