<template>
    <div class="search-full-banners">
        <div v-if="type =='procura'" class="search-results-procura-banner">
            <img src="@/assets/img/procura-banner-search-woman.webp" alt="">
            <div class="banner-content">
            <div class="title">{{__('Ainda não achou um lugar para chamar de lar?')}}</div>
            <div class="desc">{{__('Conheça o Apolar Procura. A ferramenta que conecta pessoas que estão vendendo com quem está procurando.')}}</div>
            <a href="https://procura.apolar.com.br" target="_blank" class="know-procura-bt">{{__('CONHEÇA O APOLAR PROCURA')}}</a>
            </div>
        </div>

        <boxConsortiumVue v-if="type =='condominio'" :layout="'banner'" :additional_emails="additional_emails" />
    </div>
</template>
<script>
import boxConsortiumVue from './box-consortium.vue';

export default {
  props: {
    layout: {
      type: String,
      default() {
        return "banner"
      }
    },

    type: {
      type: String,
      default() {
        return "procura"
      }
    },

    additional_emails: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    boxConsortiumVue
  },
  data() {
    return {
      showForm: false
    };
  },
  computed: {
  },
  methods: {
   
  },
  mounted() {
  }
}
</script>
<style>
</style>
