/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import './registerServiceWorker'
import GlobalDirectives from "./globalDirectives";
import LoadScript from 'vue-plugin-load-script';

import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);
Auth.configure(awsExports);

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import VueGtm from '@gtm-support/vue2-gtm';
// Vue.use(VueGtm, {
//   id: 'GTM-W424HK5'
// })

Date.prototype.getWeekNumber = function(){
  var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
};

Vue.use(BootstrapVue)

//import AmplifyVue from '@aws-amplify/ui-vue';

Vue.use(LoadScript);

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

const advanced_filters = Vue.observable({ filters: {} })

Object.defineProperty(Vue.prototype, '$advanced_filters', {
  get () {
    return advanced_filters.filters
  },
  
  set (value) {
    advanced_filters.filters = value
  }
})

Vue.config.productionTip = false;
Vue.use(Argon);
Vue.use(GlobalDirectives);
Vue.use(VueCookies, { expires: '7d'})

//Vue.use(AmplifyVue);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
