<template>
    <div class="apolar-advanced-search">
        <i class="fa fa-caret-up" aria-hidden="true"></i>
        <div class="container">
            <i class="fa fa-times close-apolar-advanced-search" aria-hidden="true"></i>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Cidade</label>
                        <v-select v-model="filters.city" :options="citiesOptions"></v-select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Bairro</label>
                        <v-select v-model="filters.district" :options="districtOptions"></v-select>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Faixa de Preço</label>
                        <div class="row">
                            <div class="col-md-5 col-sm-5">
                                <input type="text" data-price="min" v-money="moneyMask"  class="form-control">
                            </div>
                            <div class="col-md-2"><span class="at">até</span></div>
                            <div class="col-md-5 col-sm-5">
                                 <input type="text" data-price="max" v-money="moneyMask" class="form-control">
                            </div>

                            <div v-if="priceRangeOptions.min > 0" class="col-md-12">
                                <base-slider @input="updateFieldPrice" v-model="filters.price"  :range="priceRangeOptions" :options="{'step':10000}"></base-slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Quartos</label>
                        <CheckNumbers :value="filters.bedrooms" v-on:check="(nQuautos)=>changeFilterValue('bedrooms', nQuautos)"></CheckNumbers>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Vagas</label>
                        <CheckNumbers :value="filters.garage" v-on:check="(nQuautos)=>changeFilterValue('garage', nQuautos)"></CheckNumbers>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row-buttons">
                <div class="col-md-4">
                    <button v-on:click="clearFilters" class="apolar-bt apolar-bt-ouline">Limpar todos os filtros</button>
                </div>
                <div class="col-md-4">
                    <button v-on:click="applyFilter" class="apolar-bt">Aplicar Filtro</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import {VMoney} from 'v-money'

import CheckNumbers from "./check-numbers.vue";
import PropertiesService from "../../../services/PropertiesService";
import jQuery from "jquery";

export default {
  directives: {money: VMoney},
  components: {CheckNumbers, vSelect},
  data() {
    return {
        filters: {
            city:'',
            district:'',
            price:[0,100],
            bedrooms: 0,
            garage: 0,
            size: 9
        },
        cities:[],
        types:[],
        price_range:[0,1000],
        moneyMask: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        }
    };
  },
  computed: {
      citiesOptions() {
          return this.cities.map(item=>{return item.city})
      },
      districtOptions() {
        this.filters.district = ""
        if (this.filters.city == null || this.filters.city == ""){
            return []
        }
        let districts = this.cities.filter(item => item.city == this.filters.city)[0].districts
        
        return districts.sort(function (a, b) {
            return a.localeCompare(b);
        });
        
      },
      priceRangeOptions() {
          console.log({min: this.price_range[0], max: this.price_range[1]})
          return {min: this.price_range[0], max: this.price_range[1]}
      }
  },
  methods: {
      changeFilterValue(field, value) {

          Vue.set(this.filters, field, value)
      },
      applyFilter() {
          
            jQuery(this.$el).slideUp()

            Vue.set(this, '$advanced_filters', {})
            Vue.set(this, '$advanced_filters', this.filters)

            this.$router.push({
                name: "lancamentos-property-search",
                query:{uid:  Math.random().toString(16).slice(2)},
                params: {filters: this.filters}
            });

            
      },
      clearFilters() {
          this.filters.city = ''
          this.filters.district = ''
          Vue.set(this.filters, "price", [this.priceRangeOptions.min, this.priceRangeOptions.max])
          setTimeout(()=>{
              Vue.set(this.filters, "price", [this.priceRangeOptions.min, this.priceRangeOptions.max])
          },100)
          this.filters.bedrooms = 0
          this.filters.garage = 0

          jQuery("input[data-price='min']").val(this.number_format(this.priceRangeOptions.min, 2, ',', '.'))
          jQuery("input[data-price='max']").val(this.number_format(this.priceRangeOptions.max, 2, ',', '.'))
      },

      updateFieldPrice() {
          jQuery("input[data-price='min']").val(this.number_format(this.filters.price[0], 2, ',', '.'))
          jQuery("input[data-price='max']").val(this.number_format(this.filters.price[1], 2, ',', '.'))
      }
  },
  mounted() {
      jQuery(".close-apolar-advanced-search").on("click", ()=>{
          jQuery(this.$el).slideUp()
      })

      jQuery("input[data-price]").on('keyup', (e) => {
          let price_type = jQuery(e.target).attr('data-price')
          let price_value = jQuery(e.target).val().replaceAll('.','').replaceAll(',','.').replaceAll('R$', '')
          let price_index = price_type == 'min' ? 0 : 1
          let other_price_index = price_index == 0 ? 1 : 0

          let filters_price = JSON.parse(JSON.stringify(this.filters.price))
          filters_price[price_index] = price_value
          filters_price[other_price_index] = this.number_format(filters_price[other_price_index], 2, '.', '')

          Vue.set(this.filters, "price", filters_price)

      })

    



    PropertiesService.getFilters()
    .then(
        response => {
            Vue.set(this, "cities", response.data.cities)
            Vue.set(this, "types", response.data.types)
            Vue.set(this, "price_range", response.data.price_range)

            let price = response.data.price_range

            console.log("price antes: ", price)

            price[0] = parseFloat(this.number_format(price[0], 2, '.', ''))
            price[1] = parseFloat(this.number_format(price[1], 2, '.', ''))

            console.log("price formated: ", price)

            Vue.set(this.filters, "price", price)

            jQuery("input[data-price='min']").val(this.number_format(this.filters.price[0], 2, ',', '.'))
            jQuery("input[data-price='max']").val(this.number_format(this.filters.price[1], 2, ',', '.'))
        }
    )

  },
};
</script>
<style>
</style>