<template>
    <!--property_url-->
    <div  target="_blank" :href="property_url" class="property-lancamentos-component" :class="layoutClass + shortstayClass + temporadaClass">
        <div class="property-photos-area">
            <!-- <img v-if="property.IsFeiraoApolar == 1" class="black-week-seal black-week-seal-top" src="@/assets/img/black-week-top.webp" alt=""> -->
    
            <VueSlickCarousel :arrows="true" :dots="true" :slidesToShow="1">
                <template v-for="photo,key in photos" >
                    <a target="_blank" :href="property_url" v-bind:key="`photo_${key}`">
                        <img width="1" height="1" :alt="`Referencia ${property.referencia}`" :src="photo">
                    </a>
                </template>
            </VueSlickCarousel>
            
        </div>

        <a class="info-area-wrapper" target="_blank" :href="property_url">

            <button @click="shareThis" class="share">
                <img width="1" height="1" src="@/assets/img/details/details-sharer.webp" alt="Compartilhar imóvel">
            </button>

            <h2 class="property-info-area">
                <div class="property-type">{{property.tipo}}</div>
                <div class="property-price">
                    <span class="small">A partir de</span>
                    R$ {{current_price}}
                </div>
                <div class="property-address">
                    {{address_info}}
                    <span class="small">{{property.bairro}}, {{property.cidade}}</span>
                </div>
            </h2>

            <div v-if="layout == 'list'" class="property-resume" v-html="resume"></div>

            <div class="property-features">
                <div v-if="!areaTypes" class="feature car">
                    <img width="1" height="1" src="@/assets/img/lancamentos/rule-icon.webp" alt="Area">
                    {{parking_info}}
                </div>
                <div v-if="!areaTypes && property.finalidade != 'Comercial'" class="feature bed">
                    <img width="1" height="1" src="@/assets/img/lancamentos/bed-icon.webp" alt="Quartos">
                    {{bedrooms_info}}
                </div>
                <div class="feature ruler">
                    <img width="1" height="1" src="@/assets/img/lancamentos/car-icon.webp" alt="Vagas">
                    {{area_info}}
                </div>
                <div v-if="!areaTypes" class="feature bw">
                    <img width="1" height="1" src="@/assets/img/lancamentos/shower-icon.webp" alt="Banheiros">
                    {{bathroom_info}}
                </div>
            </div>

        </a>

        <a class="info-area-prices-wrapper" target="_blank" :href="property_url">

            <div @click="shareThis" class="share-this site-icons icon-share"></div>

            <div class="property-prices">
                <div v-if="old_price" class="property-last-price">R$ {{old_price}}</div>
                <div 
                    class="property-current-price"
                    :class="old_price ? 'highlighted':''"
                >
                    R$ {{current_price}}
                </div>

                <div v-if="property.vlrcondominio && property.vlrcondominio > 0" class="property-codominum-price">
                    Cond. R$ {{number_format(property.vlrcondominio, 2, ',', '.')}}
                </div>
            </div>

            <div class="contact-area-button">
                <button @click.stop.prevent="contactProperty" class="contact">{{__('Contatar')}}</button>
            </div>

        </a>

    </div>
</template>
<script>
import "@/assets/scss/custom/lancamentos/components/_property.scss"
import { Carousel, Slide } from "vue-carousel";

import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    Carousel,
    Slide,
    VueSlickCarousel
  },
  props: {
    property: {
      type: Object,
      default() {
        return {}
      }
    },
    preview_images: {
        type: Number,
        default() {
            return 1
        }
    },
    layout: {
        type: String,
        default() {
            return 'grid'
        }
    }
  },
  computed: {

    areaTypes() {
        return ['Terreno', 'Sítio', 'Chácara', 'Área', 'Área Condominio Fechado'].indexOf(this.property.tipo) != -1 ? true : false
    },

    shortstayClass() {
        if (typeof this.property.nomeEvento != "undefined" && this.property.nomeEvento.indexOf('shortstay') != -1) {
            return ' shortstay-property'
        } else {
            return ''
        }
    },

    temporadaClass() {
        if (typeof this.property.nomeEvento != "undefined" && this.property.nomeEvento.indexOf('temporada') != -1) {
            return ' temporada-property'
        } else {
            return ''
        }
    },

    layoutClass() {
        return ` layout-${this.layout}`
    },

    property_url() {
        return this.get_property_url(this.property)
    },

    parking_info() {
        let parking = parseInt(this.property.garagem)
        if ( parking <= 1 ) {
            return `${parking} vaga`
        } else {
            return `${parking} vagas`
        }
    },

    bedrooms_info() {
        let bedrooms = parseInt(this.property.dormitorios)
        if ( bedrooms <= 1 ) {
            return `${bedrooms} quarto`
        } else {
            return `${bedrooms} quartos`
        }
    },

    area_info() {
        return this.areaTypes ? `${Math.trunc(this.property.areaterreno)} m²` : `${Math.trunc(this.property.area_total)} m²`
    },

    bathroom_info() {
        return `${Math.trunc(this.property.banheiro)}`
    },

    old_price() {
        if ( this.property.ValorAnterior && this.property.ValorAnterior > this.property.valor_considerado ) {
            return this.number_format(this.property.ValorAnterior, 2, ',', '.')
        }
        return null
    },

    current_price() {
        return this.number_format(this.property.valor_considerado, 2, ',', '.')
    },

    countdow() {
        let countdow = null

        if ( this.property.situacao.toLowerCase().indexOf('reservad') != -1 ) {
            return countdow
        }

        if ( 
                parseInt(this.property.IsFeiraoApolar) == 1 && 
                typeof this.property.FimPromocao != "undefined" && 
                typeof this.property.FimPromocao ) {

            let today = new Date()
            let end = new Date(this.property.FimPromocao)
            let diff_time = Math.abs(end - today);
            let diff_days = Math.ceil(diff_time / (1000 * 60 * 60 * 24));

            return diff_days <= 1 ? `${diff_days} dia` : `${diff_days} dias`

        }

        return countdow
    },

    reserved() {
        return this.property.situacao.toLowerCase().indexOf('reservad') != -1
    },

    photos() {
        let usage_photos = [this.principal_photo]
        let photos = this.property.popup_fotos.Foto.map(f => f['URLArquivo'][0])
        photos = photos.filter(f => f != this.property.foto_principal)
        if (this.preview_images >  1) {
            usage_photos = usage_photos.concat(photos.slice(0, this.preview_images-1))
        }
        return usage_photos
    },

    principal_photo() {
        return this.property.foto_principal.indexOf('semfoto.jpg') != -1 ?  require("@/assets/img/no-photo.webp") : this.property.foto_principal
    },

    address_info() {
        let num = this.layout == 'grid' ? 30 : 100
        return this.property.endereco.length > num ? this.property.endereco.slice(0, num) + "..."  : this.property.endereco
    },

    resume() {
        let num = 150
        return this.property.descricao.length > num ? this.property.descricao.slice(0, num) + "..."  : this.property.descricao
    },

    property_title: function() {
      var title = ""

      if (Object.keys(this.property).length > 0) {
        title = `${this.property.tipo} ${this.property.finalidade} para ${(this.property.transacao == "Vendas" ? "Venda" : "Locação")} no ${this.property.bairro} em ${this.property.cidade}, ${this.area_info}`
      }

      return title
    }
  },
  data() {
    return {
    };
  },
  methods: {
    openProperty() {
        
        if ( this.property.transacao == "Lancamento" ) {

            if ( typeof this.property['linksite'] != "undefined" && this.property['linksite'] ) {
                window.open(this.property['linksite'], '_blank').focus()
                return false
            }

            let slug = this.slugify(`${this.property.cidade}-${this.property.bairro}-${this.property.condominio}-${this.property.referencia}`)
            this.$router.push({
                name: 'property-details',
                params: {
                    city: this.slugify(this.property.cidade),
                    district: this.slugify(this.property.bairro),
                    slug: slug,
                }
            })

        } else {

            let slug = this.slugify(`${this.property.transacao}-${this.property.finalidade}-${this.property.tipo}-${this.property.cidade}-${this.property.bairro}-${this.property.referencia}`)
            
            this.$router.push({
                name: 'principal-property-details',
                params: {
                    city: this.slugify(this.property.cidade),
                    district: this.slugify(this.property.bairro),
                    slug: slug,
                }
            })


        }

    },
    slugify(text) {
      return text
        .toString()                           // Cast to string (optional)
        .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                                  // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-')            // Replace spaces with -
        .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    },
    shareThis() {
        if (navigator.share) {
            navigator.share({
                title: `${this.property_title} - Apolar Imóveis`,
                url: this.property_url
            })
        }
    },
    contactProperty() {
        this.$emit('contactProperty', this.property)
    },
  },
  mounted() {
  }
};
</script>
<style>
</style>
