import MainService from './MainService'
class PropertiesService extends MainService {

    constructor() {
        super()
        this.api_name = "ApolarNew"
        this.api_path = "franchise"
    }

    getFilters = async(index_name=null) => {
        return this.doRequest("get", `filters`)
    }

    search = async(search_data) => {
        return this.doRequest("post",`search`, search_data)
    }


}

export default new PropertiesService();