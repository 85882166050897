<template>
    <div class="form-search-component">
        <div class="row main-row">
            <div class="col-md-12" :class="onlysearch ? 'only-search' : ''">
                <div class="form-search-box">
                    <form action="">
                        <div class="mobile-header">
                            <h3>Filtros</h3>
                            <div class="close-filter-box" @click="
                                () => {
                                    $emit('closesearchform');
                                }
                            "></div>
                        </div>

                        <div class="applied-filters">
                            <div class="actions">
                                <div class="label">
                                    {{ __("Filtros") }}
                                </div>
                                <div class="clear-all" @click="clearFilter()">
                                    {{ __("Limpar tudo") }}
                                </div>
                            </div>
                            <div class="box" :class="applied_filters_count > 0 ? 'has-filters-applied' : ''">
                                <div class="placeholder-area" @click="show_applied_filters = !show_applied_filters">
                                    <div class="label">
                                        {{ __("Pesquisa atual") }}
                                    </div>
                                    <div class="count-applied">
                                        {{ applied_filters_label }}
                                    </div>
                                </div>

                                <div class="filters-applied-area"
                                    v-show="show_applied_filters && applied_filters_count > 0">
                                    <template v-for="(_filter, key) in applied_filters_list">
                                        <div v-bind:key="`filter_${key}`" class="filter-applied-item"
                                            @click="removeFilter(_filter)">
                                            {{ __(_filter.label) }}
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="business-button">
                            <label class="business_button" :class="filters.business == 'Vendas' ? 'checked' : ''"
                                for="business_vendas" @click="
                                    () => {
                                        filters.business_subfilter = 'Residencial';
                                    }
                                ">
                                <span>{{ __("COMPRAR") }}</span>
                                <input name="business" v-model="filters.business" type="radio" value="Vendas"
                                    id="business_vendas" />
                            </label>

                            <label class="business_button" :class="filters.business == 'Locacao' ? 'checked' : ''"
                                for="business_locacao" @click="
                                    () => {
                                        filters.business_subfilter = 'Mensal';
                                    }
                                ">
                                <span>{{ __("ALUGAR") }}</span>
                                <input name="business" v-model="filters.business" type="radio" value="Locacao"
                                    id="business_locacao" />
                            </label>
                        </div>

                        <div class="main-filters input-filters" :class="!show_other_types ? 'no-border' : ''">
                            <div v-show="!use_advanced_filters" class="row">
                                <div class="col-md-12">
                                    <div class="input search_by">
                                        <label class="use_icon gps" for="">
                                            <span class="text">Buscar por:</span>
                                        </label>
                                        <VSelectInfinite ref="vselectsearchby" :options="filters_options.open_search"
                                            :placeholder="__('Bairro, cidade, endereço ou referência')
                                                " v-model="filters.open_search" v-on:input="handleOpenSearch"
                                            :min_search="true" />
                                    </div>
                                </div>
                            </div>

                            <div v-show="use_advanced_filters" class="row">
                                <div class="col-md-12">
                                    <div class="input">
                                        <label class="use_icon pin" for="">
                                            <span class="text">País</span>
                                        </label>
                                        <VSelectInfinite ref="vselectcountry" :options="paisOptions"
                                            :placeholder="__('Procurar por pais')" v-model="filters.country" v-on:input="
                                                () => {
                                                    filters.district = [];
                                                }
                                            " />
                                    </div>
                                </div>
                            </div>

                            <div v-show="use_advanced_filters" class="row">
                                <div class="col-md-12">
                                    <div class="input">
                                        <label class="use_icon pin" for="">
                                            <span class="text">{{ __("Cidade") }}</span>
                                        </label>
                                        <VSelectInfinite ref="vselectcity" :options="citiesOptions"
                                            :placeholder="__('Procurar por cidade')" v-model="filters.city" v-on:input="
                                                () => {
                                                    filters.district = [];
                                                }
                                            " />
                                    </div>
                                </div>
                            </div>

                            <div v-if="lang != 'es'" v-show="use_advanced_filters" class="row">
                                <div class="col-md-12">
                                    <div class="input">
                                        <label class="use_icon target" for="">
                                            <span class="text">Bairro</span>
                                        </label>

                                        <VSelectMultipleVue placeholder="Procurar por bairro" v-model="filters.district"
                                            :options="districtOptions" v-on:input="changePropertyTypeCombo()"
                                            :empty_label="filters.city
                                                ? 'Nenhuma opção encontrada.'
                                                : 'Nenhuma cidade selecionada.'
                                                " />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input input-reference">
                                        <label class="use_icon reference" for="">
                                            <span class="text">{{ __("Referência") }}</span>
                                        </label>

                                        <VSelectInfinite ref="vselectreference" :options="filters_options.references"
                                            :placeholder="__('Digite uma referência')" v-model="filters.reference" />
                                    </div>
                                </div>
                            </div>

                            <div v-show="filters.business == 'Vendas'" class="sales-pre-purpose">
                                <div class="pre-purpose-option" :class="pre_purpose == 'tolive' ? 'active' : ''" @click="
                                    () => {
                                        pre_purpose = 'tolive';
                                        filters.business_subfilter = 'Residencial';
                                    }
                                ">
                                    {{ __("Imóvel para morar") }}
                                </div>
                                <div class="pre-purpose-option" :class="pre_purpose == 'commercial' ? 'active' : ''"
                                    @click="
                                        () => {
                                            pre_purpose = 'commercial';
                                            filters.business_subfilter = 'Comercial';
                                        }
                                    ">
                                    {{ __("Comercial") }}
                                </div>
                            </div>

                            <div v-if="
                                (filters.business &&
                                    filters.business_subfilter != 'Comercial') ||
                                filters.business == 'Locacao'
                            " class="business-sub-filters" :class="`business-${filters.business}`">
                                <div v-for="(sub, subkey) in showSubFiltersByLang(
                                    filters_options.sub_filters[filters.business]
                                )" v-show="(lang != 'es' &&
                                    (sub.label != 'Comercial' ||
                                        filters.business == 'Locacao')) ||
                                    (lang == 'es' &&
                                        sub.label != 'Comercial' &&
                                        sub.label != 'Residencial' &&
                                        sub.label != 'Temporário' &&
                                        sub.label != 'Mensal')
                                    " class="button" :class="filters.business_subfilter == sub.label ? 'checked' : ''
                                        " v-bind:key="`sub_${sub.label}_${subkey}`" @click="
                                () => {
                                    filters.business_subfilter = sub.label;
                                }
                            ">
                                    {{ sub.label }}
                                </div>
                            </div>

                            <div class="row mb-2" v-show="visual_types && visual_types.length > 0">
                                <div class="col-12">
                                    <div class="business-visual-types">
                                        <div v-for="(type, idx) in visual_types" v-bind:key="`visual-type-${idx}`"
                                            class="visual-type" :class="getVisualTypeClass(type)" v-on:click="
                                                () => {
                                                    handlePropertyTypeButtons(type.id);
                                                }
                                            ">
                                            <img :width="type.size.split('x')[0]" :height="type.size.split('x')[1]"
                                                :alt="type.label" :src="type.img" />
                                            <span>{{ __(type.label) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="!show_other_types" class="row trigger-property-types">
                                <div class="col-md-12">
                                    <div @click="
                                        () => {
                                            show_other_types = !show_other_types;
                                        }
                                    " class="others-properties-types">
                                        {{ __("Outros tipos de imóveis") }}
                                    </div>
                                </div>
                            </div>

                            <div v-show="show_other_types" class="row property-types-wrapper mt-3">
                                <div class="col-md-12">
                                    <div class="input input-property-type">
                                        <label class="use_icon house" for="">
                                            <span class="text">{{ __("Tipo de imóveis") }}:</span>
                                        </label>

                                        <VSelectMultipleVue placeholder="Selecione o tipo de imóvel"
                                            v-model="filters.property_type" :options="property_type_purpose"
                                            v-on:input="changePropertyTypeCombo()" />
                                    </div>
                                </div>
                            </div>

                            <div v-if="lang != 'es'" class="row mb-4 mt-3 no-border"
                                v-show="visual_types && visual_types.length > 0">
                                <div class="col-md-12">
                                    <b-form-checkbox v-model="filters.in_condominium" name="check-button" switch>
                                        {{ __("Somente imóveis em condomínio fechado") }}
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <div class="row mb-3 no-border" v-show="visual_types && visual_types.length > 0">
                                <div class="col-md-12">
                                    <div class="filter-numbers">
                                        <label for="">{{ __("Quantidade de Quartos") }}</label>
                                        <checkNumbersVue :multiple="true" :all_label_plus="false"
                                            v-model="filters.bedrooms"></checkNumbersVue>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3 no-border" v-show="visual_types && visual_types.length > 0">
                                <div class="col-md-12">
                                    <div class="filter-range">
                                        <label for="">{{ __("Faixa de preço") }}</label>

                                        <!-- <div v-if="lang == 'es'" class="currency-types">
                                            <div class="currency-option" 
                                                :class="use_currency == 'guarani' ? 'active': ''" 
                                                @click="()=>{use_currency = 'guarani';}">
                                                <div class="check-box"></div>
                                                <div class="curreny-label">{{ __("Guarani") }}</div>
                                            </div>

                                            <div class="currency-option"
                                                :class="use_currency == 'dolar' ? 'active' : ''" @click="
                                                    () => {
                                                        use_currency = 'dolar';
                                                    }
                                                ">
                                                <div class="check-box"></div>
                                                <div class="curreny-label">{{ __("Dólar") }}</div>
                                            </div>

                                            <div class="currency-option" :class="use_currency == 'real' ? 'active' : ''"
                                                @click="
                                                    () => {
                                                        use_currency = 'real';
                                                    }
                                                ">
                                                <div class="check-box"></div>
                                                <div class="curreny-label">{{ __("Real") }}</div>
                                            </div>
                                        </div> -->

                                        <div class="filter-range-fields">
                                            <div class="filter-input">
                                                <input v-money="moneyMask" v-model="filters.price_min" type="text"
                                                    placeholder="De" />
                                            </div>
                                            <div class="filter-input">
                                                <input v-money="moneyMask" v-model="filters.price_max" type="text"
                                                    placeholder="Até" />
                                            </div>
                                        </div>
                                        <b-form-checkbox v-if="lang != 'es'" v-model="filters.include_condominium_price"
                                            name="check-button" switch>
                                            {{ __("Incluir o preço do condomínio") }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3 no-border" v-show="visual_types && visual_types.length > 0">
                                <div class="col-md-12">
                                    <div class="filter-numbers">
                                        <label for="">{{ __("Quantidade de garagens") }}</label>
                                        <checkNumbersVue :multiple="true" :all_label_plus="false"
                                            v-model="filters.garage"></checkNumbersVue>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3 no-border" v-show="visual_types && visual_types.length > 0">
                                <div class="col-md-12">
                                    <div class="filter-numbers">
                                        <label for="">{{ __("Quantidade de banheiros") }}</label>
                                        <checkNumbersVue :multiple="true" :all_label_plus="false"
                                            v-model="filters.bathrooms"></checkNumbersVue>
                                    </div>
                                </div>
                            </div>

                            <div v-if="lang != 'es'" class="row mb-3 no-border"
                                v-show="visual_types && visual_types.length > 0">
                                <div class="col-md-12">
                                    <div class="filter-range">
                                        <label for="">{{ __("Área útil") }}</label>
                                        <div class="filter-range-fields">
                                            <div class="filter-input">
                                                <input v-money="areaMask" v-model="filters.area_min" type="text"
                                                    placeholder="Min." />
                                            </div>
                                            <div class="filter-input">
                                                <input v-money="areaMask" v-model="filters.area_max" type="text"
                                                    placeholder="Max." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="lang != 'es'" class="tag-filters-area mt-5">
                                <template v-for="(tfilter, key) in filters_options.flag_filters">
                                    <div v-bind:key="`tag_${key}`" class="tag-filter">
                                        <div class="tag-filter-header" v-on:click.stop="toogleTagFilter">
                                            <span v-on:click.stop="toogleTagFilter">{{
                                                tfilter.label
                                                }}</span>
                                            <div v-on:click.stop="toogleTagFilter" class="icon"></div>
                                        </div>
                                        <div class="tag-filter-options" style="display: none">
                                            <template v-for="(topt, optkey) in tfilter.options">
                                                <div v-bind:key="`opt_${optkey}`" class="tag-filter-option"
                                                    :class="handleTagOptionClass(tfilter.type, topt)"
                                                    @click="handleTagOption(tfilter.type, topt)">
                                                    {{ topt }}
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div v-if="isMobile" class="row">
                                <div class="col-md-12">
                                    <div @click="search" class="filter-button">
                                        <span>{{ __("Aplicar Filtros") }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import "@/assets/scss/custom/principal/components/_form_search.scss";
import jQuery from "jquery";
import _ from "lodash";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { VMoney } from "v-money";
import VSelectInfinite from "../VSelectInfinite.vue";
import VSelectMultipleVue from "../VSelectMultiple.vue";
import checkNumbersVue from "../check-numbers.vue";

import PropertiesService from "../../../../services/PropertiesService";

export default {
    directives: { money: VMoney },
    components: {
        vSelect,
        VSelectInfinite,
        VSelectMultipleVue,
        checkNumbersVue,
    },
    props: {
        onlysearch: {
            type: Boolean,
            default() {
                return false;
            },
        },
        openAdvanced: {
            type: Boolean,
            default() {
                return true;
            },
        },
        clearFilterOnOpen: {
            type: Boolean,
            default() {
                return false;
            },
        },
        districts: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        var _filters = {
            business: "Vendas", //ok
            business_subfilter: "Residencial", //ok
            reference: "", //ok
            city: null, //ok
            country: null,
            district: [], //ok
            property_type: [], //ok
            property_type_combo: [], // ok
            bedrooms: [], //ok
            garage: [], //ok
            bathrooms: [], //ok
            price_max: null, //ok
            price_min: null, //ok
            area_max: null, //ok
            area_min: null, //ok
            address: null, //ok
            address_number: null,
            open_search: "", //ok
            in_condominium: false, // ok,
            include_condominium_price: false,
            conveniences: [],
            recreation: [],
            facilities: [],
            rooms: [],
        };

        console.log("this.$advanced_filters: ", this.$advanced_filters);
        Object.keys(this.$advanced_filters).forEach((key) => {
            _filters[key] = this.$advanced_filters[key];
        });

        var _pre_purpose = "tolive";

        if (this.$advanced_filters["business_subfilter"] == "Comercial") {
            _pre_purpose = "commercial";
        }

        return {
            filters: _filters,
            show_applied_filters: false,
            pre_purpose: _pre_purpose,
            filters_options: {
                sub_filters: {
                    Vendas: [
                        {
                            id: "",
                            label: "Residencial",
                            types: [
                                {
                                    id: "Apartamento",
                                    label: "Apartamento",
                                    img: require("@/assets/img/home/apartamento.webp"),
                                    size: "63x63",
                                },
                                {
                                    id: "Casa|Sobrado",
                                    label: "Casa e sobrado",
                                    img: require("@/assets/img/home/casa-sobrado.webp"),
                                    size: "53x53",
                                },
                                {
                                    id: "Studio|Kitnet",
                                    label: "Studio e kitnet",
                                    img: require("@/assets/img/home/studio.webp"),
                                    size: "63x63",
                                },
                                {
                                    id: "Terreno",
                                    label: "Terreno",
                                    img: require("@/assets/img/home/terreno.webp"),
                                    size: "51x51",
                                },
                                {
                                    id: "Garden",
                                    label: "Garden",
                                    img: require("@/assets/img/home/garden.webp"),
                                    size: "61x61",
                                },
                                {
                                    id: "Cobertura",
                                    label: "Cobertura",
                                    img: require("@/assets/img/home/cobertura.webp"),
                                    size: "62x66",
                                },
                            ],
                        },
                        {
                            id: "",
                            label: "Comercial",
                            types: [
                                {
                                    id: "Sala",
                                    label: "Sala",
                                    img: require("@/assets/img/home/sala-predio.webp"),
                                    size: "62x63",
                                },
                                {
                                    id: "Loja",
                                    label: "Loja",
                                    img: require("@/assets/img/home/loja.webp"),
                                    size: "56x56",
                                },
                                {
                                    id: "Casa|Sobrado",
                                    label: "Casa comercial",
                                    img: require("@/assets/img/home/casa-comercial.webp"),
                                    size: "61x60",
                                },
                                {
                                    id: "Barracão",
                                    label: "Barracão",
                                    img: require("@/assets/img/home/barracao.webp"),
                                    size: "56x56",
                                },
                                {
                                    id: "Área",
                                    label: "Área",
                                    img: require("@/assets/img/home/area.webp"),
                                    size: "61x61",
                                },
                                {
                                    id: "Conjunto",
                                    label: "Conjunto",
                                    img: require("@/assets/img/home/conjunto.webp"),
                                    size: "61x61",
                                },
                            ],
                        },
                        {
                            id: "",
                            label: "Lançamentos",
                            types: [
                                {
                                    id: "Apartamento",
                                    label: "Apartamento",
                                    img: require("@/assets/img/home/apartamento.webp"),
                                    size: "63x63",
                                },
                                {
                                    id: "Casa|Sobrado",
                                    label: "Casa e sobrado",
                                    img: require("@/assets/img/home/casa-sobrado.webp"),
                                    size: "53x53",
                                },
                                {
                                    id: "Studio|Kitnet",
                                    label: "Studio e kitnet",
                                    img: require("@/assets/img/home/studio.webp"),
                                    size: "63x63",
                                },
                                {
                                    id: "Terreno",
                                    label: "Terreno",
                                    img: require("@/assets/img/home/terreno.webp"),
                                    size: "51x51",
                                },
                                {
                                    id: "Garden",
                                    label: "Garden",
                                    img: require("@/assets/img/home/garden.webp"),
                                    size: "61x61",
                                },
                                {
                                    id: "Cobertura",
                                    label: "Cobertura",
                                    img: require("@/assets/img/home/cobertura.webp"),
                                    size: "62x66",
                                },
                            ],
                        },
                        {
                            id: "",
                            label: "Triple A",
                            types: [
                                {
                                    id: "Apartamento",
                                    label: "Apartamento",
                                    img: require("@/assets/img/home/apartamento.webp"),
                                    size: "63x63",
                                },
                                {
                                    id: "Casa|Sobrado",
                                    label: "Casa e sobrado",
                                    img: require("@/assets/img/home/casa-sobrado.webp"),
                                    size: "53x53",
                                },
                                {
                                    id: "Studio|Kitnet",
                                    label: "Studio e kitnet",
                                    img: require("@/assets/img/home/studio.webp"),
                                    size: "63x63",
                                },
                                {
                                    id: "Terreno",
                                    label: "Terreno",
                                    img: require("@/assets/img/home/terreno.webp"),
                                    size: "51x51",
                                },
                                {
                                    id: "Garden",
                                    label: "Garden",
                                    img: require("@/assets/img/home/garden.webp"),
                                    size: "61x61",
                                },
                                {
                                    id: "Cobertura",
                                    label: "Cobertura",
                                    img: require("@/assets/img/home/cobertura.webp"),
                                    size: "62x66",
                                },
                            ],
                        },
                    ],
                    Locacao: [
                        {
                            id: "",
                            label: "Mensal",
                            types: [
                                {
                                    id: "Apartamento",
                                    label: "Apartamento",
                                    img: require("@/assets/img/home/apartamento.webp"),
                                    size: "63x63",
                                },
                                {
                                    id: "Casa|Sobrado",
                                    label: "Casa e sobrado",
                                    img: require("@/assets/img/home/casa-sobrado.webp"),
                                    size: "53x53",
                                },
                                {
                                    id: "Studio|Kitnet",
                                    label: "Studio e kitnet",
                                    img: require("@/assets/img/home/studio.webp"),
                                    size: "63x63",
                                },
                                // {"id":"Terreno", "label":"Terreno", "img": require("@/assets/img/home/terreno.webp"), "size": "51x51"}
                            ],
                        },
                        {
                            id: "",
                            label: "Temporário",
                            types: [
                                {
                                    id: "Apartamento",
                                    label: "Apartamento",
                                    img: require("@/assets/img/home/apartamento.webp"),
                                    size: "63x63",
                                },
                                {
                                    id: "Studio",
                                    label: "Studio",
                                    img: require("@/assets/img/home/studio.webp"),
                                    size: "63x63",
                                },
                                {
                                    id: "Casa|Sobrado",
                                    label: "Casa e sobrado",
                                    img: require("@/assets/img/home/casa-sobrado.webp"),
                                    size: "53x53",
                                },
                                // {"id":"Outros", "label":"Outros imóveis", "img": require("@/assets/img/home/terreno.webp"), "size": "51x51"}
                            ],
                        },
                        {
                            id: "",
                            label: "Comercial",
                            types: [
                                {
                                    id: "Sala",
                                    label: "Sala",
                                    img: require("@/assets/img/home/sala-predio.webp"),
                                    size: "62x63",
                                },
                                {
                                    id: "Loja",
                                    label: "Loja",
                                    img: require("@/assets/img/home/loja.webp"),
                                    size: "56x56",
                                },
                                // {"id":"Casa|Sobrado", "label":"Casa comercial", "img": require("@/assets/img/home/casa-comercial.webp"), "size":"61x60"},
                                {
                                    id: "Barracão",
                                    label: "Barracão",
                                    img: require("@/assets/img/home/barracao.webp"),
                                    size: "56x56",
                                },
                            ],
                        },
                    ],
                },
                cities: [],
                countries: [
                    "Brasil",
                    "Paraguai"
                ],
                types: [],
                references: [],
                adresses: [],
                open_search: [],
                flag_filters: [
                    {
                        type: "conveniences",
                        label: "Conveniências",
                        options: [
                            "Banheira de hidromassagem",
                            "Ar condicionado",
                            "Aquecimento",
                            "Acesso para cadeirantes",
                            "Animais de estimação",
                            "Elevador",
                            "Internet/Internet Wireless",
                            "Lareira",
                            "Maquina de lavar roupa",
                            "Portaria",
                            "Sauna",
                            "SPA",
                            "Móveis planejados",
                            "Mobiliado",
                            "Jardim",
                        ],
                    },
                    {
                        type: "recreation",
                        label: "Lazer",
                        options: [
                            "Churrasqueira",
                            "Academia",
                            "Edícula",
                            "Espaço gourmet",
                            "Espaço pet",
                            "Piscina",
                            "Brinquedoteca",
                            "Playground",
                            "Garden",
                            "Quadra de beach tênis",
                            "Quadra poliesportiva",
                            "Quintal",
                            "Salão de jogos",
                            "Salão de festas",
                            "Ginásio",
                            "Ofuro",
                            "Terraço",
                        ],
                    },
                    {
                        type: "facilities",
                        label: "Comodidades",
                        options: [
                            "Acessibilidade",
                            "Almoxarifado",
                            "Ático",
                            "Área de serviço",
                            "Área comum",
                            "Bicicletário",
                            "Biblioteca",
                            "Canil",
                            "Cinema",
                            "Deck",
                            "Dependência de empregada",
                            "Depósito",
                            "Despensa",
                            "Escritorio",
                            "Estacionamento",
                            "Sacada",
                            "Recepção",
                            "Sótão",
                            "Sala de ginástica",
                            "Varanda",
                            "Mezanino",
                            "Closet",
                            "Sala de reunião",
                        ],
                    },
                    {
                        type: "rooms",
                        label: "Cômodos",
                        options: [
                            "Atelier",
                            "Auditório",
                            "Banheiro de serviço",
                            "Banheiro social",
                            "Banheiro suíte",
                            "Barracão",
                            "Circulação",
                            "Copa",
                            "Corredor",
                            "Cozinha",
                            "Demi-suíte",
                            "Dormitório",
                            "Entrada",
                            "Escada",
                            "Frente",
                            "Fundos",
                            "Recepção",
                            "Garagem",
                            "Garagem coberta",
                            "Gazebo",
                            "Hall",
                            "Jardim de inverno",
                            "Lago",
                            "Lavabo",
                            "Lavanderia",
                            "Porão",
                            "Sala comercial",
                            "Sala de estar",
                            "Sala de jantar",
                            "Sala de TV",
                        ],
                    },
                ],
            },
            show_other_types: false,
            use_advanced_filters: true,
            filter_by_ref: false,
            areaMask: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: " m²",
                precision: 2,
                masked: false /* doesn't work with directive */,
            },
            enable_auto_search: false,
            all_filters_loaded: false,
            old_site_url: "https://www.apolar.com.br",
            use_currency: "real",
        };
    },
    computed: {
        computedFilters: function () {
            return Object.assign({}, this.filters);
        },

        citiesOptions() {
            var cities = this.filters_options.cities
                                             .filter(item => item.pais === this.filters.country)
                                             .map(item => item.city);
                                             console.log(cities)
            return cities;
        },

        paisOptions() {
            return [
                "Brasil",
                "Paraguai"
            ];
        },
        districtOptions() {
            //this.filters.district = []
            if (this.filters.city == null || this.filters.city == "") {
                return [];
            }

            let districts = this.filters_options.cities
                .filter((item) => item.city == this.filters.city)[0]
                .districts.sort(function (a, b) {
                    return a.localeCompare(b);
                });

            //to remove register duplicated and wrong
            districts.forEach((district, key) => {
                let district_sanitized = district
                    .normalize("NFD")
                    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, "");

                let exists = districts.indexOf(district_sanitized);
                if (exists != -1 && district_sanitized != district) {
                    delete districts[exists];
                }
            });

            return districts.sort(function (a, b) {
                return a.localeCompare(b);
            });
        },

        visual_types() {
            if (this.filters.business && this.filters.business_subfilter) {
                let sub_filters =
                    this.filters_options__sub_filters[this.filters.business];
                let sub_filter = sub_filters.filter(
                    (sub) => sub.label == this.filters.business_subfilter
                );
                return sub_filter.length > 0 ? sub_filter[0]["types"] : null;
            }
            return null;
        },

        property_type_purpose() {
            let comercial_types = [
                "Barracão",
                "Conjunto",
                "Hotel",
                "Loja",
                "Ponto",
                "Prédio",
                "Sala",
                "Sobreloja",
                "Área",
                "Casa",
            ];
            if (this.filters.business_subfilter == "Comercial") {
                return comercial_types.sort(function (a, b) {
                    return a.localeCompare(b);
                });
            }

            let residencial_types = this.filters_options.types.filter(
                (type) => comercial_types.indexOf(type) == -1
            );
            if (this.lang != "es") {
                residencial_types = residencial_types.concat("Área", "Casa");
            }

            if (this.lang == "es") {
                residencial_types = residencial_types.filter(
                    (type) => type != "Flat" && type != "Terreno"
                );
            }

            return residencial_types.sort(function (a, b) {
                return a.localeCompare(b);
            });
        },

        applied_filters_count() {
            let count_filters = 0;

            let ignore_filters = [
                "business",
                "business_subfilter",
                "property_type_combo",
                "address_number",
                "open_search",
                "include_condominium_price",
                "price_min",
                "price_max",
                "area_min",
                "area_max",
            ];

            let price_min = this.filters.price_min
                ? parseFloat(
                    this.filters.price_min
                        .replace("R$", "")
                        .replaceAll(".", "")
                        .replace(",", ".")
                )
                : 0;
            let price_max = this.filters.price_max
                ? parseFloat(
                    this.filters.price_max
                        .replace("R$", "")
                        .replaceAll(".", "")
                        .replace(",", ".")
                )
                : 0;
            let consider_price = price_min > 0 || price_max > 0 ? 1 : 0;

            let area_min = this.filters.area_min
                ? parseFloat(
                    this.filters.area_min
                        .replace("m²", "")
                        .replaceAll(".", "")
                        .replace(",", ".")
                )
                : 0;
            let area_max = this.filters.area_max
                ? parseFloat(
                    this.filters.area_max
                        .replace("m²", "")
                        .replaceAll(".", "")
                        .replace(",", ".")
                )
                : 0;
            let consider_area = area_min > 0 || area_max > 0 ? 1 : 0;

            Object.keys(this.filters).forEach((filter) => {
                if (ignore_filters.indexOf(filter) != -1) {
                    return false;
                }

                if (Array.isArray(this.filters[filter])) {
                    count_filters += this.filters[filter].length;
                } else if (this.filters[filter]) {
                    count_filters++;
                }
            });

            count_filters = count_filters + consider_price + consider_area;
            return count_filters;
        },

        applied_filters_label() {
            if (this.applied_filters_count == 0) {
                return this.__("Nenhum filtro");
            } else {
                return this.applied_filters_count == 1
                    ? "1 Filtro aplicado"
                    : `${this.applied_filters_count} Filtros aplicados`;
            }
        },

        applied_filters_list() {
            let ignore_filters = [
                "business",
                "business_subfilter",
                "property_type_combo",
                "address_number",
                "open_search",
                "include_condominium_price",
                "price_min",
                "price_max",
                "area_min",
                "area_max",
            ];

            let applied_filters = [];

            Object.keys(this.filters).forEach((filter) => {
                if (ignore_filters.indexOf(filter) != -1) {
                    return false;
                }

                if (!this.filters[filter]) {
                    return false;
                }

                let filter_name = filter;
                let filter_value = this.filters[filter];
                let filter_label = this.filters[filter];
                let filter_structure = Array.isArray(this.filters[filter])
                    ? "list"
                    : "string";

                if (Array.isArray(this.filters[filter])) {
                    this.filters[filter].forEach((val) => {
                        let local_filter_label = val;

                        switch (filter_name) {
                            case "district":
                                local_filter_label = `${val}`;
                                break;

                            case "garage":
                                local_filter_label =
                                    val == 1 ? val + this.__(" vaga") : val + this.__(" vagas");
                                break;

                            case "bedrooms":
                                local_filter_label =
                                    val == 1
                                        ? val + this.__(" quarto")
                                        : val + this.__(" quartos");
                                break;

                            case "bathrooms":
                                local_filter_label =
                                    val == 1
                                        ? val + this.__(" banheiro")
                                        : val + this.__(" banheiros");
                                break;

                            default:
                                break;
                        }

                        let applied_filter = {
                            name: filter_name,
                            value: val,
                            label: local_filter_label,
                            structure: filter_structure,
                        };

                        applied_filters.push(applied_filter);
                    });
                } else {
                    let applied_filter = {
                        name: filter_name,
                        value: filter_value,
                        label: filter_label,
                        structure: filter_structure,
                    };

                    applied_filters.push(applied_filter);
                }
            });

            let price_min = this.filters.price_min
                ? parseFloat(
                    this.filters.price_min
                        .replace("R$", "")
                        .replaceAll(".", "")
                        .replace(",", ".")
                )
                : 0;
            let price_max = this.filters.price_max
                ? parseFloat(
                    this.filters.price_max
                        .replace("R$", "")
                        .replaceAll(".", "")
                        .replace(",", ".")
                )
                : 0;

            if (price_min > 0 || price_max > 0) {
                let filter_name = "";
                let local_filter_label = "";
                let val = "";

                if (price_min > 0) {
                    filter_name = "price_min";
                    local_filter_label = `A partir de R$${this.number_format(
                        price_min,
                        2,
                        ",",
                        "."
                    )} `;
                    val = price_min;
                }

                if (price_max > 0) {
                    filter_name =
                        filter_name != "" ? `${filter_name}|price_max` : "price_max";
                    val = val != "" ? `${price_min}|${price_max}` : price_max;
                    local_filter_label =
                        local_filter_label != ""
                            ? `De R$${this.number_format(
                                price_min,
                                2,
                                ",",
                                "."
                            )} a R$${this.number_format(price_max, 2, ",", ".")}`
                            : `Até R$${this.number_format(price_max, 2, ",", ".")}`;
                }

                let applied_filter = {
                    name: filter_name,
                    value: val,
                    label: local_filter_label,
                    structure: "range",
                };

                applied_filters.push(applied_filter);
            }

            let area_min = this.filters.area_min
                ? parseFloat(
                    this.filters.area_min
                        .replace("m²", "")
                        .replaceAll(".", "")
                        .replace(",", ".")
                )
                : 0;
            let area_max = this.filters.area_max
                ? parseFloat(
                    this.filters.area_max
                        .replace("m²", "")
                        .replaceAll(".", "")
                        .replace(",", ".")
                )
                : 0;

            if (area_min > 0 || area_max > 0) {
                let filter_name = "";
                let local_filter_label = "";
                let val = "";

                if (area_min > 0) {
                    filter_name = "area_min";
                    local_filter_label = `A partir de ${this.number_format(
                        area_min,
                        2,
                        ",",
                        "."
                    )}m²`;
                    val = area_min;
                }

                if (area_max > 0) {
                    filter_name =
                        filter_name != "" ? `${filter_name}|area_max` : "area_max";
                    val = val != "" ? `${area_min}|${area_max}` : area_max;
                    local_filter_label =
                        local_filter_label != ""
                            ? `De ${this.number_format(
                                area_min,
                                2,
                                ",",
                                "."
                            )}m² a ${this.number_format(area_max, 2, ",", ".")}m²`
                            : `Até ${this.number_format(area_max, 2, ",", ".")}m²`;
                }

                let applied_filter = {
                    name: filter_name,
                    value: val,
                    label: local_filter_label,
                    structure: "range",
                };

                applied_filters.push(applied_filter);
            }

            return applied_filters;
        },

        moneyMask() {
            let prefix = "R$";
            if (this.use_currency == "guarani") {
                prefix = "G$";
            } else if (this.use_currency == "dolar") {
                prefix = "$";
            }

            return {
                decimal: ",",
                thousands: ".",
                prefix: `${prefix} `,
                suffix: "",
                precision: 2,
                masked: false /* doesn't work with directive */,
            };
        },

        filters_options__sub_filters() {
            let sub_filters = this.filters_options.sub_filters;

            if (this.lang == "es") {
                sub_filters["Vendas"][0] = {
                    id: "",
                    label: "Residencial",
                    types: [
                        {
                            id: "Apartamento",
                            label: "Apartamento",
                            img: require("@/assets/img/home/apartamento.webp"),
                            size: "63x63",
                        },
                        {
                            id: "Casa|Sobrado",
                            label: "Casa e sobrado",
                            img: require("@/assets/img/home/casa-sobrado.webp"),
                            size: "53x53",
                        },
                        {
                            id: "Studio|Kitnet",
                            label: "Studio e kitnet",
                            img: require("@/assets/img/home/studio.webp"),
                            size: "63x63",
                        },
                    ],
                };
            }

            return sub_filters;
        },
    },
    methods: {
        getVisualTypeClass(type) {
            let _class = `visual-type-${this.slugify(type.id)} `;
            if (type.id == "Outros") {
                _class += `trigger-property-types `;
            }
            _class += `${this.filters.property_type_combo.indexOf(type.id) != -1 ? "active" : ""
                }`;
            return _class;
        },

        clearReferenceFilter() {
            this.filters.reference = null;
        },

        numeric_options(range = 10, label, sufix, zero_option = false) {
            let options = [];

            if (zero_option) {
                options.push({
                    label: `0`,
                    id: 0,
                });
            }

            [...Array(range).keys()].forEach((num) => {
                let opt_num = num + 1;
                let _sufix = sufix ? sufix : null;
                if (!_sufix) {
                    _sufix = opt_num >= 4 ? "+" : "";
                }
                options.push({
                    label: `${opt_num}${_sufix}`,
                    id: opt_num,
                });
            });
            return options;
        },

        checkPropertyTypeHidden() {
            // if ( this.use_advanced_filters ) {
            //     this.show_other_types = true
            // } else {
            //     this.show_other_types = false
            // }
        },

        handleOpenSearch() {
            let open_search = this.filters.open_search;
            let check_is_reference = parseInt(open_search);

            //check se é busca por numero de referencia
            if (!isNaN(check_is_reference)) {
                this.filters.reference = check_is_reference;
            } else {
                this.filters.reference = null;
                let open_search_parts = open_search.split(",");

                switch (open_search_parts.length) {
                    case 1:
                        Vue.set(this.filters, "city", open_search_parts[0].trim());
                        Vue.set(this.filters, "district", []);
                        Vue.set(this.filters, "address", null);
                        Vue.set(this.filters, "address_number", null);
                        break;

                    case 2:
                        Vue.set(this.filters, "city", open_search_parts[1].trim());
                        Vue.set(this.filters, "district", [open_search_parts[0].trim()]);
                        Vue.set(this.filters, "address", null);
                        Vue.set(this.filters, "address_number", null);
                        break;

                    case 3:
                        Vue.set(this.filters, "city", open_search_parts[2].trim());
                        //Vue.set(this.filters, 'district', [open_search_parts[1].trim()])
                        Vue.set(this.filters, "address", open_search_parts[0].trim());
                        Vue.set(this.filters, "address_number", null);
                        break;

                    case 4:
                        Vue.set(this.filters, "city", open_search_parts[3].trim());
                        //Vue.set(this.filters, 'district', [open_search_parts[1].trim()])
                        Vue.set(
                            this.filters,
                            "address_number",
                            open_search_parts[1].trim()
                        );
                        Vue.set(this.filters, "address", open_search_parts[0].trim());
                        break;

                    default:
                        break;
                }
            }

            this.loadExistingFilters(true);
        },

        //on change type by button, reflect on select field type
        handlePropertyTypeButtons(type) {
            if (type == "Outros") {
                this.show_other_types = !this.show_other_types;
                return false;
            }

            let type_index = this.filters.property_type_combo.indexOf(type);

            if (type_index == -1) {
                this.filters.property_type_combo.push(type);

                this.filters.property_type_combo.forEach((propType) => {
                    let types = propType.split("|");
                    types.forEach((type) => {
                        if (
                            this.filters.property_type.indexOf(type) == -1 &&
                            type != "Condominio"
                        ) {
                            this.filters.property_type.push(type);
                        }
                    });
                });
            } else {
                this.filters.property_type_combo.splice(type_index, 1);
                type.split("|").forEach((sub_type) => {
                    let sub_type_idx = this.filters.property_type.indexOf(sub_type);
                    if (sub_type_idx != -1) {
                        this.filters.property_type.splice(sub_type_idx, 1);
                    }
                });
            }

            // this.filters.in_condominium = false
            // if ( this.filters.property_type_combo.indexOf("Condominio") != -1 ) {
            //     this.filters.in_condominium = true
            // }
        },

        //on change type by select, reflect on buttons type
        changePropertyTypeCombo() {
            let possible_types = [];
            let possible_types_vendas = this.filters_options__sub_filters[
                "Vendas"
            ].map((item) => item["types"].map((sub) => sub.id));
            let possible_types_locacao = this.filters_options__sub_filters[
                "Locacao"
            ].map((item) => item["types"].map((sub) => sub.id));
            possible_types_vendas.forEach((list_types) => {
                possible_types = possible_types.concat(list_types);
            });
            possible_types_locacao.forEach((list_types) => {
                possible_types = possible_types.concat(list_types);
            });
            possible_types = possible_types.filter(
                (type) => type != "Casa" && type != "Studio"
            );
            possible_types = [...new Set(possible_types)];

            this.filters.property_type.forEach((type) => {
                let possible_type_idx = possible_types.findIndex(
                    (p_type) => p_type.indexOf(type) != -1
                );
                let possible_type = possible_types[possible_type_idx];

                if (
                    possible_type != -1 &&
                    this.filters.property_type_combo.indexOf(possible_type) == -1
                ) {
                    this.filters.property_type_combo.push(possible_type);
                }
            });

            this.filters.property_type_combo.forEach((propType, idx) => {
                if (typeof propType != "undefined") {
                    let type_changed = this.filters.property_type.findIndex(
                        (type) => propType.indexOf(type) != -1
                    );

                    if (type_changed == -1) {
                        this.filters.property_type_combo.splice(idx, 1);
                    }
                }
            });
        },

        search() {
            if (this.filters["reference"] && this.filters["reference"] != "") {
                this.loadPropertyByRef(this.filters["reference"]);
            }

            let filter_type =
                this.filters.property_type && this.filters.property_type.length > 0
                    ? this.slugify(this.filters.property_type[0])
                    : null;
            let purpose = this.slugify(this.filters.business_subfilter);

            if (purpose == "comercial" && filter_type == "sala") {
                filter_type = "sala-comercial";
            }

            if (this.filters.property_type_combo.length > 0 && !filter_type) {
                let type_combo = this.filters.property_type_combo[0].split("|");
                filter_type = this.slugify(type_combo[0]);
            }

            let bedrooms_label = "quartos";
            if (
                this.filters.bedrooms &&
                this.filters.bedrooms.length == 1 &&
                this.filters.bedrooms[0] <= 1
            ) {
                bedrooms_label = "quarto";
            }

            let route_params = {
                transaction: this.filters.business == "Vendas" ? "venda" : "alugar",
                type: filter_type,
                city: this.filters.city ? this.slugify(this.filters.city) : null,
                country: this.filters.country ? this.slugify(this.filters.country) : null,
                district:
                    this.filters.district && this.filters.district.length > 0
                        ? this.slugify(this.filters.district[0])
                        : null,
                address: this.filters.address
                    ? this.slugify(this.filters.address)
                    : null,
                bedrooms:
                    this.filters.bedrooms && this.filters.bedrooms.length > 0
                        ? `${this.filters.bedrooms.join("-")}-${bedrooms_label}`
                        : null,
            };

            let query = {};
            query[purpose] = null;

            let ignore_fields = [
                "business",
                "business_subfilter",
                "property_type_combo",
                "open_search",
                "city",
                "bedrooms",
            ];
            let handle_float_values = ["price_min", "price_max"];

            if (this.filters.property_type.length == 1) {
                ignore_fields.push("property_type");
            }

            if (this.filters.district.length == 1) {
                ignore_fields.push("district");
            }

            if (this.filters.price_min == "R$ 0,00") {
                ignore_fields.push("price_min");
            }

            if (this.filters.price_max == "R$ 0,00") {
                ignore_fields.push("price_max");
            }

            if (this.filters.area_min == "0,00 m²") {
                ignore_fields.push("area_min");
            }

            if (this.filters.area_max == "0,00 m²") {
                ignore_fields.push("area_max");
            }

            Object.keys(this.filters).forEach((field) => {
                let value = this.filters[field];
                if (value && ignore_fields.indexOf(field) == -1) {
                    query[field] = value;
                }
            });

            if (this.isMobile) {
                this.$emit("appliedfilterscount", this.applied_filters_count);
                if (jQuery(".filter-properties").hasClass("mobile-form-opened")) {
                    this.$emit("closesearchform");
                }
            }

            Vue.set(this, "$advanced_filters", {});
            Vue.set(this, "$advanced_filters", this.filters);

            this.$emit("filterApplied", true);
            this.$router.push({
                name: "principal-property-search",
                query: query,
                params: route_params,
            });
        },

        onTheFlyForm(url, data) {
            var new_form = jQuery("<form>", {
                action: url,
                method: "POST",
                target: "_top",
            });

            var fields_list = ["bairro", "tipo", "quartos", "vagas"];

            Object.keys(data.filters).forEach((field) => {
                let value = data.filters[field];

                let input_name = `filters[${field}]${fields_list.indexOf(field) != -1 ? "[]" : ""
                    }`;

                if (typeof value == "object") {
                    value.forEach((value_item) => {
                        let input = jQuery("<input>", {
                            name: input_name,
                            value: value_item,
                            type: "hidden",
                        });

                        new_form.append(input);
                    });
                } else {
                    let input = jQuery("<input>", {
                        name: input_name,
                        value: value,
                        type: "hidden",
                    });

                    new_form.append(input);
                }
            });

            new_form.appendTo("body").submit();
        },

        prepare_send_url() {
            var business = "alugar";
            var city = "";
            var district = "";
            var property_type = "";

            if (
                this.filters.business == "Vendas" ||
                this.filters.business_subfilter == "Lançamentos"
            ) {
                business = "venda";
            }

            if (this.filters.business_subfilter == "Temporário") {
                business = "temporada";
            }

            if (this.filters.business_subfilter == "Triple A") {
                business = "triplea/comprar";
            }

            if (this.filters.city != "" && this.filters.city != null) {
                city = "/" + this.slugify(this.filters.city);
            }

            if (this.filters.district && this.filters.district.length > 0) {
                district = this.slugify(this.filters.district[0]);
            }

            if (this.filters.property_type && this.filters.property_type.length > 0) {
                property_type = "/" + this.slugify(this.filters.property_type[0]);
            }

            var url = "/" + business + property_type + city;
            if (district != "") {
                url += "/" + this.slugify(district);
            }

            if (this.filters.address != "" && this.filters.address != null) {
                url += "/" + this.slugify(this.filters.address);
            }

            return this.old_site_url + url;
        },

        clearFilter(business_change = false) {
            if (!business_change) {
                this.filters.business = "Vendas";
                this.filters.business_subfilter = "Residencial";
            }

            this.filters.reference = null;
            this.filters.city = null;
            this.filters.country = null;
            this.filters.district = [];
            this.filters.property_type = [];
            this.filters.property_type_combo = [];
            this.filters.bedrooms = [];
            this.filters.garage = [];
            this.filters.bathrooms = [];
            this.filters.price_max = null;
            this.filters.price_min = null;

            this.filters.area_max = null;
            this.filters.area_min = null;

            this.filters.address = null;
            this.filters.in_condominium = false;
            this.filters.conveniences = [];
            this.filters.recreation = [];
            this.filters.facilities = [];
            this.filters.rooms = [];

            if (typeof this.$refs["vselectsearchby"] != "undefined") {
                this.$refs["vselectsearchby"].$children[0].clearSelection();
            }

            if (typeof this.$refs["vselectpropertytype"] != "undefined") {
                //this.$refs['vselectpropertytype'].$children[0].clearSelection()
            }

            if (typeof this.$refs["vselectcity"] != "undefined") {
                this.$refs["vselectcity"].$children[0].clearSelection();
            }

            if (typeof this.$refs["vselectcountry"] != "undefined") {
                this.$refs["vselectcountry"].$children[0].clearSelection();
            }
            if (typeof this.$refs["vselectreference"] != "undefined") {
                this.$refs["vselectreference"].$children[0].clearSelection();
            }

            if (typeof this.$refs["vselectdistrict"] != "undefined") {
                //this.$refs['vselectdistrict'].$children[0].clearSelection()
            }
        },

        loadExistingFilters(reload_filters = false) {
            if (typeof this.$advanced_filters == "undefined") {
                return false;
            }

            let advanced_filters = this.clearFilterOnOpen
                ? {}
                : JSON.parse(JSON.stringify(this.$advanced_filters));
            let use_filters = reload_filters
                ? JSON.parse(JSON.stringify(this.filters))
                : advanced_filters;

            if (!use_filters) {
                return false;
            }

            console.log("use_filters: ", use_filters);

            setTimeout(() => {
                this.$refs["vselectcity"].$children[0].updateValue("");
                this.$refs["vselectreference"].$children[0].updateValue("");

                Object.keys(use_filters).forEach((key, i) => {
                    if (key == "district") {
                        //use_filters[key] =
                        let _use_filters = [];
                        if (use_filters[key].length > 0) {
                            use_filters[key].forEach((dstc) => {
                                let exists = _use_filters.indexOf(dstc);
                                let exists_normal = _use_filters.indexOf(dstc.normalize("NFD"));

                                if (exists == -1 && exists_normal == -1) {
                                    _use_filters.push(dstc);
                                }
                            });
                        }
                        use_filters[key] = _use_filters;
                    }

                    this.filters[key] = null;
                    this.filters[key] = use_filters[key];
                    //Vue.set(this.filters, key, use_filters[key])

                    if (key == "city" && use_filters[key]) {
                        this.$refs["vselectcity"].$children[0].updateValue(
                            use_filters[key]
                        );
                    }

                    if (key == "reference" && use_filters[key]) {
                        this.$refs["vselectreference"].$children[0].updateValue(
                            use_filters[key]
                        );
                    }
                });

                if (
                    use_filters["property_type_combo"] &&
                    use_filters["property_type_combo"].length > 0
                ) {
                    use_filters["property_type_combo"].forEach((type_combo) => {
                        let types_in_combo = type_combo.split("|");
                        types_in_combo.forEach((type) => {
                            type = type.trim();
                            if (
                                this.filters["property_type"].indexOf(type) == -1 &&
                                type != "Condominio"
                            ) {
                                this.filters["property_type"].push(type);
                            }
                        });
                    });
                }

                //this.filters.reference = null
            }, 100);
        },

        toogleTagFilter(e) {
            let toogleElm = jQuery(e.target)
                .closest(".tag-filter")
                .find(".tag-filter-options");
            jQuery(toogleElm).slideToggle({
                duration: 200,
                start: function () {
                    jQuery(this).css("display", "flex");
                },
            });
        },

        handleTagOption(tag_type, tag_value) {
            let tag_value_idx = this.filters[tag_type].indexOf(tag_value);
            if (tag_value_idx == -1) {
                this.filters[tag_type].push(tag_value);
            } else {
                this.filters[tag_type].splice(tag_value_idx, 1);
            }
        },

        handleTagOptionClass(tag_type, tag_value) {
            let tag_value_idx = this.filters[tag_type].indexOf(tag_value);
            if (tag_value_idx != -1) {
                return "checked";
            }
            return "";
        },

        removeFilter(applied_filter) {
            if (applied_filter.structure == "list") {
                let value_idx = this.filters[applied_filter.name].indexOf(
                    applied_filter.value
                );
                if (value_idx != -1) {
                    let filter_value = JSON.parse(
                        JSON.stringify(this.filters[applied_filter.name])
                    );
                    filter_value.splice(value_idx, 1);
                    //this.filters[applied_filter.name].splice(value_idx, 1)
                    Vue.set(this.filters, applied_filter.name, filter_value);
                }
                this.changePropertyTypeCombo();

                if (this.districts != this.filters.district) {
                    this.$emit("change_district", this.filters.district);
                }
            } else if (applied_filter.structure == "string") {
                this.filters[applied_filter.name] = null;

                if (applied_filter.name == "city") {
                    this.$refs["vselectcity"].$children[0].clearSelection();
                }


                if (applied_filter.name == "country") {
                    this.$refs["vselectcountry"].$children[0].clearSelection();
                }

                if (applied_filter.name == "reference") {
                    this.$refs["vselectreference"].$children[0].clearSelection();
                }
            } else {
                if (
                    applied_filter.name.indexOf("area_min") != -1 ||
                    applied_filter.name.indexOf("area_max") != -1
                ) {
                    this.filters.area_max = null;
                    this.filters.area_min = null;
                }

                if (
                    applied_filter.name.indexOf("price_min") != -1 ||
                    applied_filter.name.indexOf("price_max") != -1
                ) {
                    this.filters.price_max = null;
                    this.filters.price_min = null;
                }
            }
        },

        loadPropertyByRef(reference) {
            PropertiesService.getByReference(reference, this.data_index).then(
                (resp) => {
                    if (resp.success) {
                        this.filters.business = resp.data.transacao;
                        this.filters.business_subfilter =
                            resp.data.transacao == "Vendas" ? "Residencial" : "Mensal";
                        this.$emit("updateFilterBusiness", this.filters.business);
                    }
                }
            );
        },

        showSubFiltersByLang(sub_filters) {
            console.log("sub_filters: ", sub_filters);
            if (this.lang == "es") {
                return sub_filters.filter(
                    (sf) => sf.label != "Lançamentos" && sf.label != "Triple A"
                );
            }
            return sub_filters;
        },

        applieSearch: _.debounce(function (self) {
            self.search();
        }, 2000),
    },

    mounted() {
        this.filters.business = "Vendas";

    this.use_currency = this.lang == 'es' ? 'dolar' : 'real'

        PropertiesService.getFilters(this.data_index).then((response) => {
            //this.filters_options.cities = response.data.cities
            this.filters_options.types = response.data.types;
            this.filters_options.references = response.data.referencias;
            this.filters_options.adresses = response.data.enderecos;

            let cities = [];
            let districts = [];
            let address = [];

            //adjusted to place curitiba on first position
            let curitiba_obj = response.data.cities.filter(
                (city_obj) => city_obj.city == "Curitiba"
            );
            let other_cities = response.data.cities.filter(
                (city_obj) => city_obj.city != "Curitiba"
            );
            this.filters_options.cities = curitiba_obj.concat(other_cities);

            this.filters_options.cities.forEach((city) => {
                cities.push(city.city);
                city.districts.forEach((district) => {
                    districts.push(`${district}, ${city.city}`);
                });
            });

            // response.data.enderecos.forEach((addrs)=>{
            //     address.push(`${addrs.address}, ${addrs.district}, ${addrs.city}`)
            //     addrs.numbers.forEach((number)=>{
            //         address.push(`${addrs.address}, ${number}, ${addrs.district}, ${addrs.city}`)
            //     })
            // })
            response.data.enderecos.forEach((addrs) => {
                let _address_without_number = `${addrs.address}, ${addrs.district}, ${addrs.city}`;
                let _address_with_number = `${addrs.address}, ${addrs.number}, ${addrs.district}, ${addrs.city}`;

                if (address.indexOf(_address_without_number) == -1) {
                    address.push(_address_without_number);
                }

                if (address.indexOf(_address_with_number) == -1) {
                    address.push(_address_with_number);
                }
            });

            this.filters_options.open_search = cities.concat(
                districts,
                address,
                this.filters_options.references
            );

            this.loadExistingFilters();
            this.all_filters_loaded = true;
        });

        document
            .querySelector(".form-search-component")
            .addEventListener("click", (e) => {
                let el_is_property_type = e.target.closest(".property-types-wrapper");
                let el_is_trigger = e.target.closest(".trigger-property-types");
                if (!el_is_property_type && !el_is_trigger) {
                }
            });
    },

    watch: {
        districts: function (districts) {
            districts.forEach((district) => {
                let exists = this.filters.district.indexOf(district);
                if (exists == -1) {
                    this.filters.district.push(district);
                }
            });

            this.filters.district.forEach((district, key) => {
                let exists = districts.indexOf(district);
                if (exists == -1) {
                    this.filters.district.splice(key, 1);
                }
            });
        },

        filters: {
            handler: function (newValue, oldValue) {
                if (this.enable_auto_search && !this.isMobile) {
                    this.applieSearch(this);
                }
            },
            deep: true,
        },
        $advanced_filters: function (filters) {
            if (
                typeof filters["header_search"] != "undefined" &&
                filters["header_search"]
            ) {
                delete filters["header_search"];
                this.loadExistingFilters();
            }
        },
    },
};
</script>