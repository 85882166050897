<template>
    <div class="indique-banner-home-action">
        <div class="container row">
            <div class="main-box">
                <h3>Anuncie seu imóvel
                    em um dos maiores
                    portais imóbiliarios do
                    Brasil</h3>

                <img src="@/assets/img/indique/megafone.webp" width="1" height="1" alt="Mãos e Chaves">
                <div class="second-main-box">
                    <div class="message-second-main">
                        <div>
                            <span>Seja para vender ou para alugar, na Apolar Imóveis seu imóvel tem a visibilidade
                                necessária para não ficar desocupado. Anuncie agora mesmo com a Apolar. </span>
                        </div>

                    </div>
                    <div class="anuncie-link">
                        <a target="_blank" href="https://anuncie.apolar.com.br/">
                            Anuncie seu imóvel
                        </a>
                        <div class="arrow-right"></div>
                    </div>
                </div>


            </div>
            <div class="small-box">

                <VueSlickCarousel v-bind="slideSettings">
                    <template v-for="item, key in slides">
                        <div @click="navigateToUrl(item.url)">
                            <div v-bind:key="`item_${key}`">
                                <div class="slide-item">
                                    <img :src="item.img" :alt="item.title" width="1" height="1" />
                                    <div class="box-info">
                                        <div class="title">{{ item.title }}</div>
                                        <div class="info">{{ item.description }}</div>
                                        <div class="hline"> </div>
                                        <span v-if="item.text">{{ item.text.info }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </template>
                </VueSlickCarousel>

            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/scss/custom/principal/components/_banner-indique-action.scss"

import Vue from "vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: {
        VueSlickCarousel
    },
    props: {
    },
    data() {
        return {
            slides: [
                {   
                    "url":"https:/apolar.com.br/blog/venda/porque-escolher-a-apolar-para-locar-ou-vender-meu-imovel/",
                    "img": require("@/assets/img/indique/keys_on_hands.png"),
                    "author":"Damares Moraes",
                    "title": "Porque escolher a Apolar para locar ou vender meu imóvel?",
                    "description": "A decisão de vender ou locar o imóvel nem sempre é fácil e, em muitos casos, pode parecer complexo ou burocrático para quem não está acostumado com a negociação.",
                    "text": {
                        "info": "Postado 06 Jan de 2023"
                    }
                },
                {
                    "url":"https://apolar.com.br/blog/personnalite/alugar-meu-imovel-4-dicas/",
                    "img": require("@/assets/img/indique/couple_keys.png"),
                    "author":"Damares Moraes",
                    "title": "Como alugar meu imóvel? 4 dicas para alugar mais rápido!",
                    "description": "Ter uma renda extra é o desejo da maioria dos brasileiros, ainda mais em tempos difíceis economicamente. Por isso, ter um imóvel para locação é uma boa opção para garantir mais tranquilidade no final do mês.",
                    "text": {
                        "info": "Postado 09 Jun de 2018"
                    }
                }
            ]
        }
    },
    computed: {

        slideSettings() {
            let settings = {
                "dots": true,
                "arrows": true,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1
            }

            if (this.isMobile) {
                settings['slidesToShow'] = 1
                settings['dots'] = true
            }

            return settings
        },

    },
    methods: {
        navigateToUrl(url) {
            window.open(url, '_blank')
      }
    },


    mounted() {

    },

    watch: {
    }

};
</script>