<template>
    <div class="contact-form-component">
        <h2>{{ __(title) }}</h2>

        <div v-if="send_status == 'sending'" class="send-proccess">
            <bounce-loader :color="'#FFC20F'"></bounce-loader>
        </div>

        <div v-if="send_status == 'sended'" class="send-proccess">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            {{ __('Contato enviado!') }}
        </div>

        <div>
            <div class="form-input" :class="typeof this.errors['name'] != 'undefined' ? 'error' : ''">
                <label for="">
                    Nome
                </label>
                <input type="text" :placeholder="__('Apolino Apolar')" v-model="contact.name">
            </div>

            <div class="form-row">
                <div class="form-input text-50-fale-conosco" :class="typeof this.errors['email'] != 'undefined' ? 'error' : ''">
                    <label for="">
                        Email
                    </label>
                    <input type="email" :placeholder="__('Apolino@apolar.com.br')" v-model="contact.email">
                </div>

                <div class="form-input text-50-fale-conosco" :class="typeof this.errors['phone'] != 'undefined' ? 'error' : ''">
                    <label for="">
                        Telefone
                    </label>
                    <input v-mask="'(##) #####-####'" type="phone" :placeholder="__('(41) 99999-9999')"
                        v-model="contact.phone">
                </div>
            </div>

            <div class="form-input" :class="typeof this.errors['message'] != 'undefined' ? 'error' : ''">
                <label for="">
                    Mensagem
                </label>
                <textarea style="resize: none" v-model="contact.message" :placeholder="__('Mensagem')" cols="30"
                    rows="4"></textarea>
            </div>
        </div>
        <div @click="send" class="send-contact">ENVIAR</div>
    </div>
</template>
<script>
import Vue from 'vue'
import * as yup from "yup";
import { mask } from 'vue-the-mask'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import ContactService from "../../../../services/ContactService";

const validateSchema = yup.object({
    name: yup.string().required("Nome completo é obrigatório."),
    phone: yup.string().required("Telefone é obrigatório."),
    email: yup.string().required("E-mail é obrigatório.").email("E-mail inválido."),
    message: yup.string().required("Nos envie sua mensagem.")
})

export default {
    directives: { mask },
    components: {
        BounceLoader
    },
    props: {
        title: {
            type: String,
            default() {
                return "Tem mais perguntas? Fale conosco"
            }
        },
        send_to: {
            type: String,
            default() {
                return "start@ops.digital"
            }
        },
    },
    data() {
        return {
            errors: {},
            contact: {
                name: '',
                email: '',
                phone: '',
                message: ''
            },
            send_status: ''
        };
    },
    computed: {
    },
    methods: {
        send() {

            this.errors = {}

            validateSchema
                .validate(this.contact, { abortEarly: false })
                .then(async () => {

                    this.send_status = "sending"

                    let data_contact = {
                        "subject": `Contato página Indique - Enviado por ${this.contact.name}`,
                        "to": this.send_to,
                        "fields": [
                            {
                                "label": "Nome Completo",
                                "value": this.contact.name,
                            },
                            {
                                "label": "Telefone",
                                "value": this.contact.phone,
                            },
                            {
                                "label": "E-mail",
                                "value": this.contact.email,
                            },
                            {
                                "label": "Mensagem",
                                "value": this.contact.message,
                            }
                        ],
                        "_recaptcha_token": await this.createRecaptchaToken('CONTACT')
                    }

                    ContactService.sendGenericContact(data_contact)
                        .then((reponse) => {
                            this.send_status = "sended"
                        })
                        .catch((error) => {
                            this.send_fail = true
                        })
                        .finally(() => {

                            this.send_button = "ENVIAR"

                            setTimeout(() => {

                                Object.keys(this.contact).forEach((field) => {
                                    this.contact[field] = ''
                                })

                                this.send_status = ""

                            }, 4000)
                        })


                })
                .catch(err => {
                    if (typeof err.inner != "undefined") {
                        err.inner.forEach(error => {
                            Vue.set(this.errors, error.path, error.message)
                        });
                    }
                })

        }
    },
    mounted() {
        this.loadRecaptchaTag()
    }
}
</script>
<style></style>
