<template>
    <div class="advertise-search-box-component">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                <h3>{{__('Procurando um novo lar?')}}</h3>

                <carousel class="view-box" :perPageCustom="carousel_responsive">
                    <slide v-for="box,bkey  in _boxes" v-bind:key="`box_${bkey}`">
                        <div class="box">
                            <div :class="`box-icon ${box.icon}`"></div>
                            <div class="box-title">{{__(box.title)}}</div>
                            <div class="box-description">{{__(box.description)}}</div>
                            <a  target="_blank" class="box-url" :href="box.link.url">{{__(box.link.label)}}</a>
                        </div>
                    </slide>
                </carousel>

                <a target="_blank" class="box-main-button" :href="lang == 'es' ? '/venda' : '/alugar'">{{__('Buscar imóveis')}}</a>

            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { Carousel, Slide } from "vue-carousel";


export default {
  components: {
    Carousel,
    Slide
  },
  computed: {
      prepare_search() {
      },
      _boxes() {
        if (this.lang == 'es') {
            return this.boxes.filter(bx => bx.icon != 'icon-hand')
        }
        return this.boxes
      },
      carousel_responsive() {
        if (this.lang == 'es') {
            return [[300, 1],[400, 1],[800, 2],[1000, 2]]
        }
        return [[300, 1],[400, 1],[800, 2],[1000, 3]]
      }
  },
  data() {
    return {
        
        boxes: []

    };
  },
  methods: {
  },
  mounted() {

    this.boxes = [
            {
                "icon": "icon-house",
                "title": "Mais de 8.000 opções disponíveis",
                "description": "Para venda e locação, 12.000 acessos diários, o nosso portal é a melhor opção para você anunciar o seu imóvel.",
                "link": {
                    "label": "Saiba mais sobre a Apolar",
                    "url": "https://www.apolar.com.br/institucional/"
                }
            },
            {
                "icon": "icon-hand",
                "title": "Alugue sem burocracia",
                "description": "Com documentação e assinatura digital. Encontre o seu imóvel em apenas um clique, muito mais facilidade para você.",
                "link": {
                    "label": "Veja as vantagens Apolar",
                    "url": "https://www.apolar.com.br/anuncie"
                }
            },
            {
                "icon": "icon-chart",
                "title": "O imóvel dos seus sonhos está aqui",
                "description": "O imóvel dos seus sonhos está aqui. São mais de 5.000 opções disponíveis para venda, encontre o que você procura em um único portal.",
                "link": {
                    "label": "Confira as dicas de investimento",
                    "url": this.lang == 'es' ? '/venda' : "https://www.apolar.com.br/invista"
                }
            }
        ]

  }
};
</script>
<style>
</style>
