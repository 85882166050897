<template>
    <header class="header-global header-principal" :class="getHeaderClass">
        <base-nav class="navbar-main" type="" expand>
            <router-link slot="brand" class="navbar-brand" to="/">
                <img width="93" height="59" :src="apolar_logo" alt="Apolar Imóveis">
            </router-link>

            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
                <li 
                    v-for="menu,idx in menu_disposition" 
                    v-bind:key="`menu-${idx}`"
                    :class="typeof getMenu(menu).submenu !='undefined' ? 'has-submenu': ''"
                >
                    <a  target="_blank" :class="getMenu(menu).class" :href="getMenu(menu).link">
                        {{ __(getMenu(menu).label) }}
                    </a>

                    <ul v-if="getMenu(menu).submenu && getMenu(menu).submenu.length > 0" class="sub-menu">
                        <li 
                            v-for="sub,subidx in handle_submenu(menu, getMenu(menu).submenu)"
                            v-bind:key="`submenu-${subidx}`"
                        >
                            <a  target="_blank" :href="sub.link">{{ __(sub.label) }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </base-nav>
    </header>
</template>
<script>
import Vue from 'vue'
import "@/assets/scss/custom/principal/_header.scss"
import vSelect from 'vue-select'
import BaseNav from "@/components/BaseNav";

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    BaseNav
  },
  computed: {
      getHeaderClass() {
          return `header_${this.$route.name}`
      },
      apolar_logo() {
        if ( this.lang == 'es') {
            return require('@/assets/img/principal-logo-header-es.webp')
        }
        return require('@/assets/img/logo-apolar-imoveis-high.webp')
      }
  },
  data() {
    return {
        menu_disposition: [],
        menu_desktop_disposition:[
            'Comprar','Alugar','Produtos Apolar','Indique', 
            'Anunciar Imóveis', 'Calcule', 'Seja Franqueado', 'Área Cliente'
        ],
        menu_mobile_disposition:[
            'Seja Franqueado', 'Área Cliente', 'Anunciar Imóveis', 'Calcule',
            'Indique', 'Alugar', 'Comprar', 'Produtos Apolar'
        ],
        menus: [
            {
                "link": "/venda",
                "label": "Comprar",
                "class": "",
                "submenu": [
                    {
                        "link": "/venda?residencial",
                        "label": "Residencial"
                    },
                    {
                        "link": "/lancamentos",
                        "label": "Lançamentos"
                    },
                    {
                        "link": "/triplea",
                        "label": "Triple A"
                    },
                    {
                        "link": "/venda?comercial",
                        "label": "Comercial"
                    },
                    {
                        "link": "/feirao-apolar",
                        "label": "Feirão Apolar"
                    },
                    {
                        "link": "https://portugal.apolar.com.br",
                        "label": "Imóveis em Portugal"
                    },
                    {
                        "link": "https://apolar.com.br",
                        "label": "Propiedades en Brasil"
                    }
                ]
            },
            {
                "link": "/alugar",
                "label": "Alugar",
                "class": "",
                "submenu": [
                    {
                        "link": "/alugar?mensal",
                        "label": "Residencial"
                    },
                    {
                        "link": "/alugar?comercial",
                        "label": "Comercial"
                    },
                    {
                        "link": "https://shortstay.apolar.com.br/",
                        "label": "ShortStay"
                    },
                    {
                        "link": "/temporada",
                        "label": "Temporada"
                    },
                    {
                        "link": "https://opsaluguei.com.br/",
                        "label": "Ops! Aluguei"
                    }
                ]
            },
            {
                "link": "#",
                "label": "Produtos Apolar",
                "class": "",
                "submenu": [
                    {
                        "link": "https://procura.apolar.com.br/",
                        "label": "Apolar Procura"
                    },
                    {
                        "link": "https://consorcioapolar.com.br/",
                        "label": "Consórcio"
                    },
                    {
                        "link": "/feirao-apolar",
                        "label": "Feirão Apolar"
                    },
                    {
                        "link": "/lancamentos",
                        "label": "Lançamentos"
                    },
                    {
                        "link": "/triplea",
                        "label": "Triple A"
                    },
                    {
                        "link": "/invista",
                        "label": "Para Investidores"
                    },
                    {
                        "link": "/b4b",
                        "label": "Built for Business"
                    },
                    {
                        "link": "/franquias/",
                        "label": "Nossas Lojas"
                    }
                ]
            },
            {
                "link": "/indique",
                "label": "Indique",
                "class": "indique",
                "submenu": []
            },
            {
                "link": "/anuncie",
                "label": "Anunciar Imóveis",
                "class": "anunciar-imoveis",
                "submenu": []
            },
            {
                "link": "https://calcule.apolar.com.br/",
                "label": "Calcule",
                "class": "anunciar-imoveis",
                "submenu": []
            },
            {
                "link": "https://franqueado.apolar.com.br",
                "label": "Seja Franqueado",
                "class": "special-button seja-franqueado"
            },
            {
                "link": "/areacliente/",
                "label": "Área Cliente",
                "class": "special-button area-cliente"
            }
        ]
    };
  },
  methods: {

    setMenuLayout() {
        this.menu_disposition = this.menu_desktop_disposition
        if (this.isMobile) {
             this.menu_disposition = this.menu_mobile_disposition
        }

        if (this.lang == 'es') {
            //this.menu_disposition = ['Comprar','Alugar','Anunciar Imóveis', 'Seja um Franqueado']
            this.menu_disposition = ['Comprar','Alugar']
        }
    },

    getMenu(menu_name) {
        let find_menu = this.menus.filter(menu => menu.label == menu_name)
        if ( find_menu.length > 0 ) {
            var menu = find_menu[0]
            return menu
        }
        return null
    },

    handle_submenu(menu_name, submenu) {
        
        if ( this.lang == 'es' ) {
            var exclude_submenu = null
            switch (menu_name) {
                case "Comprar":
                case "Produtos Apolar":
                    exclude_submenu = ['Lançamentos', 'Triple A', 'Imóveis em Portugal']
                    break;

                case "Alugar":
                    exclude_submenu = ['ShortStay', 'Temporada', 'Ops! Aluguei']
                    break;
            
                default:
                    break;
            }

            if (exclude_submenu) {
                return submenu.filter(sbm => exclude_submenu.indexOf(sbm['label']) == -1 )
            }
        }

        if ( this.lang == 'ptBR' ) {
            var exclude_submenu = null
            switch (menu_name) {
                case "Comprar":
                case "Produtos Apolar":
                    exclude_submenu = ['Propiedades en Brasil']
                    break;
            
                default:
                    break;
            }

            if (exclude_submenu) {
                return submenu.filter(sbm => exclude_submenu.indexOf(sbm['label']) == -1 )
            }
        }

        return submenu

    }

  },
  mounted() {
     this.setMenuLayout()
  }
};
</script>
<style>
</style>
