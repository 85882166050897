export default {
    slugify(text) {
        return text
          .toString()                           // Cast to string (optional)
          .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
          .toLowerCase()                  // Convert the string to lowercase letters
          .trim()                                  // Remove whitespace from both sides of a string (optional)
          .replace(/\s+/g, '-')            // Replace spaces with -
          .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
          .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    },
    get_property_url(property, data_index) {
        let {protocol, host} = window.location

        switch (data_index) {
            case "main":
                host = "www.apolar.com.br"
                break;
            case "py":
                 host = "www.apolar.com.py"
                break;
            default:
                 host = "www.apolar.com.br"
                break;
        }

        var baseUrl = `${protocol}//${host}`
        
        if ( typeof property.nomeEvento != "undefined" && property.nomeEvento.indexOf('Real Estate') != -1 ) {

            baseUrl = `${baseUrl}/triplea`
            let goal = property.transacao == "Vendas" ? "comprar" : "alugar"
            let slug = this.slugify(`${goal}-${property.finalidade}-${property.tipo}-${property.cidade}-${property.bairro}-${property.referencia}`)
            let property_url = `${baseUrl}/${goal}/${this.slugify(property.cidade)}/${this.slugify(property.bairro)}/${slug}`
            return property_url
    
        } else if (typeof property.nomeEvento != "undefined" && property.nomeEvento.indexOf('shortstay') != -1) {
    
            baseUrl = `https://shortstay.apolar.com.br`
            let goal = "alugar"
            let slug = this.slugify(`${property.tipo}-${property.Quartos}-quartos-${property.bairro}-${property.referencia}`)
            let property_url = `${baseUrl}/${goal}/${this.slugify(property.cidade)}/${this.slugify(property.bairro)}/${slug}`
            return property_url
    
        } else if (typeof property.nomeEvento != "undefined" && property.nomeEvento.indexOf('temporada') != -1) {
    
            baseUrl = `${baseUrl}/temporada`
            let goal = "alugar"
            let slug = this.slugify(`${property.tipo}-temporada-${property.cidade}-${property.bairro}-${property.referencia}`)
            let property_url = `${baseUrl}/${this.slugify(property.cidade)}/${this.slugify(property.bairro)}/${slug}`
            return property_url
    
        } else if ( property.transacao == "Lancamento" ) {
            let slug = this.slugify(`${property.cidade}-${property.bairro}-${property.condominio}-${property.referencia}`)
            let property_url = `${baseUrl}/lancamentos/imovel/${this.slugify(property.cidade)}/${this.slugify(property.bairro)}/${slug}`
            return property_url
        } else {
            let goal = property.transacao == "Vendas" ? "venda" : "alugar"
            let slug = this.slugify(`${goal}-${property.finalidade}-${property.tipo}-${property.cidade}-${property.bairro}-${property.referencia}`) 

            let property_url = `${baseUrl}/${goal}/${this.slugify(property.cidade)}/${this.slugify(property.bairro)}/${slug}`

            if (!property.bairro) {
                property_url = `${baseUrl}/${goal}/${this.slugify(property.cidade)}/${slug}`
            }

            return property_url
        }
    }
}