<template>
    <!--property_url-->
    <div class="contact-property-modal">
        <div class="contact-property-modal-content">
            <img 
                class="close-button" 
                width="1" height="1" 
                src="@/assets/img/close-icon-default.webp" alt="Fechar"
                @click="()=>{$emit('close')}"
            />
            

            <div class="property-photos-area">
                <!-- <img v-if="property.IsFeiraoApolar == 1" class="black-week-seal black-week-seal-top" src="@/assets/img/black-week-top.webp" alt=""> -->
                <PropertySeal v-if="property.IsFeiraoApolar == 1" :property="property" />
                <PropertySeal :type="'situacao'" :property="property" />

                <VueSlickCarousel :arrows="true" :dots="false" :slidesToShow="1">
                    <template v-for="photo,key in photos" >
                        <a target="_blank" :href="property_url" v-bind:key="`photo_${key}`">
                            <img width="1" height="1" :alt="`Referencia ${property.referencia}`" :src="photo">
                        </a>
                    </template>
                </VueSlickCarousel>
                
            </div>

            <div class="info-area-wrapper">

                <div class="property-info-area">
                    <div class="property-type">
                        {{property.tipo}}

                        <button @click="shareThis" class="share">
                            <img width="1" height="1" src="@/assets/img/details/details-sharer.webp" alt="Compartilhar imóvel">
                        </button>
                    </div>
                    <div class="property-street">{{address_info}}</div>
                    <div class="property-address-others">{{property.bairro}}, {{property.cidade}}</div>
                </div>
                
                <div class="property-resume" v-html="resume"></div>

                <div class="property-features">
                    <div v-if="!areaTypes" class="feature car">{{parking_info}}</div>
                    <div v-if="!areaTypes && property.finalidade != 'Comercial'" class="feature bed">{{bedrooms_info}}</div>
                    <div class="feature ruler">{{area_info}}</div>
                    <div v-if="!areaTypes" class="feature bw">{{bathroom_info}}</div>
                </div>

                <div class="property-prices-more">
                    <div class="property-prices">
                        <div v-if="old_price" class="property-last-price">R$ {{old_price}}</div>
                        <div 
                            class="property-current-price"
                            :class="old_price ? 'highlighted':''"
                        >
                            R$ {{current_price}}
                        </div>

                         <div v-if="property.vlrcondominio && property.vlrcondominio > 0" class="property-codominum-price">
                            Cond. R$ {{number_format(property.vlrcondominio, 2, ',', '.')}}
                        </div>

                    </div>

                    <div class="clear"></div>
                </div>


                <div class="property-contact-buttons">

                    <div v-if="property.lojacelular != ''" v-on:click="openWhatsapp" class="talk-with">
                        <div class="immobile-icons whatsapp"></div>
                        <span>{{__('Fale pelo Whatsapp')}}</span>
                        <div class="clear"></div>
                    </div>

                    <div v-if="property.lojatelefone != ''" v-on:click="openFone" class="talk-with">
                        <div class="immobile-icons fone"></div>
                        <span v-if="showFone == ''" >{{__('Fale pelo Telefone')}}</span>
                        <span v-else >{{showFone}}</span>
                        <div class="clear"></div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { Carousel, Slide } from "vue-carousel";
import PropertySeal from './property-seal.vue';

import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


export default {
  components: {
    Carousel,
    Slide,
    PropertySeal,
    VueSlickCarousel
  },
  props: {
    property: {
      type: Object,
      default() {
        return {}
      }
    },
    preview_images: {
        type: Number,
        default() {
            return 1
        }
    },
    layout: {
        type: String,
        default() {
            return 'grid'
        }
    }
  },
  computed: {

    areaTypes() {
        return ['Terreno', 'Sítio', 'Chácara', 'Área', 'Área Condominio Fechado'].indexOf(this.property.tipo) != -1 ? true : false
    },

    shortstayClass() {
        if (typeof this.property.nomeEvento != "undefined" && this.property.nomeEvento.indexOf('shortstay') != -1) {
            return ' shortstay-property'
        } else {
            return ''
        }
    },

    temporadaClass() {
        if (typeof this.property.nomeEvento != "undefined" && this.property.nomeEvento.indexOf('temporada') != -1) {
            return ' temporada-property'
        } else {
            return ''
        }
    },

    layoutClass() {
        return ` layout-${this.layout}`
    },

    property_url() {
        return this.get_property_url(this.property)
    },

    parking_info() {
        let parking = parseInt(this.property.garagem)
        if ( parking <= 1 ) {
            return `${parking} vaga`
        } else {
            return `${parking} vagas`
        }
    },

    bedrooms_info() {
        let bedrooms = parseInt(this.property.dormitorios)
        if ( bedrooms <= 1 ) {
            return `${bedrooms} quarto`
        } else {
            return `${bedrooms} quartos`
        }
    },

    area_info() {
        return this.areaTypes ? `${Math.trunc(this.property.areaterreno)} m²` : `${Math.trunc(this.property.area_total)} m²`
    },

    bathroom_info() {
        return `${Math.trunc(this.property.banheiro)}`
    },

    old_price() {
        if ( this.property.ValorAnterior && this.property.ValorAnterior > this.property.valor_considerado ) {
            return this.number_format(this.property.ValorAnterior, 2, ',', '.')
        }
        return null
    },

    current_price() {
        return this.number_format(this.property.valor_considerado, 2, ',', '.')
    },

    countdow() {
        let countdow = null

        if ( this.property.situacao.toLowerCase().indexOf('reservad') != -1 ) {
            return countdow
        }

        if ( 
                parseInt(this.property.IsFeiraoApolar) == 1 && 
                typeof this.property.FimPromocao != "undefined" && 
                typeof this.property.FimPromocao ) {

            let today = new Date()
            let end = new Date(this.property.FimPromocao)
            let diff_time = Math.abs(end - today);
            let diff_days = Math.ceil(diff_time / (1000 * 60 * 60 * 24));

            return diff_days <= 1 ? `${diff_days} dia` : `${diff_days} dias`

        }

        return countdow
    },

    reserved() {
        return this.property.situacao.toLowerCase().indexOf('reservad') != -1
    },

    photos() {
        let usage_photos = [this.principal_photo]
        let photos = this.property.popup_fotos.Foto.map(f => f['URLArquivo'][0])
        photos = photos.filter(f => f != this.property.foto_principal)
        if (this.preview_images >  1) {
            usage_photos = usage_photos.concat(photos.slice(0, this.preview_images-1))
        }
        return usage_photos
    },

    principal_photo() {
        return this.property.foto_principal.indexOf('semfoto.jpg') != -1 ?  require("@/assets/img/no-photo.webp") : this.property.foto_principal
    },

    address_info() {
        let num = this.layout == 'grid' ? 30 : 100
        return this.property.endereco.length > num ? this.property.endereco.slice(0, num) + "..."  : this.property.endereco
    },

    resume() {
        let num = 150
        return this.property.descricao.length > num ? this.property.descricao.slice(0, num) + "..."  : this.property.descricao
    },

    property_title: function() {
      var title = ""

      if (Object.keys(this.property).length > 0) {
        title = `${this.property.tipo} ${this.property.finalidade} para ${(this.property.transacao == "Vendas" ? "Venda" : "Locação")} no ${this.property.bairro} em ${this.property.cidade}, ${this.area_info}`
      }

      return title
    },

    apolar_event_data: function() {
      if (Object.keys(this.property).length > 0) {
        return {
          "loja": this.property.loja,
          "referencia": this.property.referencia,
          "transacao": this.property.transacao,
          "tipo_imovel": this.property.tipo_search,
          "cidade": this.property.cidade_search,
          "bairro": this.property.bairro_search,
          "area": this.property.area_total,
        }
      }
      return null
    }
  },
  data() {
    return {
        showFone: '',
    };
  },
  methods: {
    openProperty() {
        
        if ( this.property.transacao == "Lancamento" ) {

            if ( typeof this.property['linksite'] != "undefined" && this.property['linksite'] ) {
                window.open(this.property['linksite'], '_blank').focus()
                return false
            }

            let slug = this.slugify(`${this.property.cidade}-${this.property.bairro}-${this.property.condominio}-${this.property.referencia}`)
            this.$router.push({
                name: 'property-details',
                params: {
                    city: this.slugify(this.property.cidade),
                    district: this.slugify(this.property.bairro),
                    slug: slug,
                }
            })

        } else {

            let slug = this.slugify(`${this.property.transacao}-${this.property.finalidade}-${this.property.tipo}-${this.property.cidade}-${this.property.bairro}-${this.property.referencia}`)
            
            this.$router.push({
                name: 'principal-property-details',
                params: {
                    city: this.slugify(this.property.cidade),
                    district: this.slugify(this.property.bairro),
                    slug: slug,
                }
            })


        }

    },
    slugify(text) {
      return text
        .toString()                           // Cast to string (optional)
        .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                                  // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-')            // Replace spaces with -
        .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    },
    shareThis() {
        if (navigator.share) {
            navigator.share({
                title: `${this.property_title} - Apolar Imóveis`,
                url: this.property_url
            })
        }
    },
    openWhatsapp() {
            this.register_apolar_event('click', 'whatsapp', this.apolar_event_data)
            var text = this.__("Olá preciso de informações sobre esse imóvel: ") +  window.location.href;
            window.open(
                'https://api.whatsapp.com/send?phone=+55'+ this.property.lojacelular.replace(/\D/g,'') +'&text=' + encodeURIComponent(text),
                '_blank'
            );
        },

    openFone() {
        this.register_apolar_event('click', 'telefone', this.apolar_event_data)
        this.showFone = this.property.lojatelefone
        if ( this.isMobile ) {
            window.location.href = 'tel:' + this.property.lojatelefone.replace(/\D/g,'');
        }
    },
  },
  mounted() {
  }
};
</script>
<style>
</style>
