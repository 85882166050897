<template>
    <div class="page-principal-contact">
          <div class="container">
              <h1>{{__('Trabalhe conosco')}}</h1>
  
              <div class="row">
                  <div class="col-sm-12 col-md-12">
                      <div class="description">
                          {{__("A Apolar Imóveis é movida por propósitos. E ser uma rede sustentável de negócios imobiliários que gere felicidade as pessoas é a nossa prioridade. É com esse foco que alcançamos resultados nunca atingidos. E para isso, buscamos talentos de inúmeras áreas, que assim como nós, tenham desejo de inovar e se desenvolver.")}}<br/>
                          {{__("Juntos somos mais fortes!")}}
                      </div>
  
                  </div>
                  <div class="col-sm-12 col-md-12 mt-3">
                    <div class="contact-form-component">
                        <h2>{{__("Envie seu curriculo")}}</h2>

                        <div v-if="send_status == 'sending'" class="send-proccess">
                            <bounce-loader :color="'#FFC20F'"></bounce-loader>
                        </div>

                        <div v-if="send_status == 'sended'" class="send-proccess">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                            {{__('Contato enviado!')}}
                        </div>

                        <div v-if="send_status == ''">
                            <div class="form-input" :class="typeof this.errors['name'] != 'undefined' ? 'error': ''">
                                <input 
                                    type="text" :placeholder="__('Nome completo')" v-model="form.name">
                            </div>

                            <div class="form-input" :class="typeof this.errors['email'] != 'undefined' ? 'error': ''">
                                <input 
                                    type="email" :placeholder="__('E-mail')" v-model="form.email">
                            </div>

                            <div class="form-input" :class="typeof this.errors['phone'] != 'undefined' ? 'error': ''">
                                <input 
                                    v-mask="'(##) #####-####'" type="phone" :placeholder="__('Telefone')" v-model="form.phone">
                            </div>

                            <div class="form-input" :class="typeof this.errors['message'] != 'undefined' ? 'error': ''">
                                <textarea 
                                    v-model="form.message" :placeholder="__('Apresente-se e nos conte um pouco mais sobre você')" cols="30" rows="10"></textarea>
                            </div>

                            <div class="form-input" >
                               <input type="file">
                            </div>

                            <div @click="send" class="send-contact">ENVIAR</div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
    </div>
  </template>
  <script>
  import Vue from "vue";
  import "@/assets/scss/custom/principal/_contact.scss"
  import ContactForm from '../components/apolar/principal/contact-form.vue';
  
  export default {
    name: "faq",
    components: {
      ContactForm
    },
    metaInfo() {
      return {
        title: this.__("Fale Conosco | Imobiliária em Curitiba - Imóveis à Venda e Locação"),
        meta: [
          {
            vmid: "description",
            name: "description",
            content:"Quer ser um franqueado ou conhecer todas as nossas lojas? Entre em contato conosco econheça todo o negócio Apolar Imóveis!"
          }
        ]
      };
    },
    data() {
      return {
        errors: {},
        form: {
            name: '',
            email: '',
            phone: '',
            message: '',
            curriculum: ''
        },
        send_status: ''
      };
    },
    computed: {
  
  
    },
    methods: {
      toogle_faq(faq_idx) {
          this.opened_faq = this.opened_faq == faq_idx ? null : faq_idx
      }
    },
    mounted() {
   
    }
  };
  </script>
  