<template>
    <div class="lancamento-banner-home-invista-action">
        <div class="container">
            <div class="main-box">
                <h3>Invista em imóveis e veja seus rendimentos aumentarem</h3>
                <div class="description">
                    Imóveis com retorno financeiro imediato. Seu dinheiro parado no banco pode gerar muito mais retorno quando investido no lugar certo.
                </div>
                <a href="#">
                    Ver imóveis para investir
                </a>
                <img src="@/assets/img/lancamentos/lancamentos-home-buildings.webp" width="1" height="1" alt="Invista em Imóveis">
            </div>
            <div class="small-box">

                <VueSlickCarousel v-bind="slideSettings">
                    <template v-for="item,key in slides" >
                        <div v-bind:key="`item_${key}`">
                            <div class="slide-item">
                                <img :src="item.img" :alt="item.title" width="1" height="1" />
                                <div class="box-info">
                                    <div class="title">{{item.title}}</div>
                                    <div class="info">{{item.description}}</div>
                                    <a v-if="item.link" :href="item.link.url">
                                        {{item.link.label}} 
                                        <img src="@/assets/img/lancamentos/arrow-right-squad.webp" width="1" height="1" :alt="item.link.label">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                </VueSlickCarousel>

            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/scss/custom/lancamentos/components/banner-home-action.scss"

import Vue from "vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    VueSlickCarousel
  },
  props: {
  },
  data() {
    return {
        slides: [
            {
                "img": require("@/assets/img/lancamentos/arch-planning.webp"),
                "title": "Anuncie com a Apolar",
                "description": "Seu imóvel pode ja ter um comprador, angarie seu empreendimento.",
                "link": {
                    "label": "Anunciar meu imóvel",
                    "url": "#"
                }
            },
            // {
            //     "img": require("@/assets/img/lancamentos/arch-planning.webp"),
            //     "title": "Anuncie com a Apolar",
            //     "description": "Seu imóvel pode ja ter um comprador, angarie seu empreendimento.",
            //     "link": {
            //         "label": "Anunciar meu imóvel",
            //         "url": "#"
            //     }
            // },
            // {
            //     "img": require("@/assets/img/lancamentos/arch-planning.webp"),
            //     "title": "Anuncie com a Apolar",
            //     "description": "Seu imóvel pode ja ter um comprador, angarie seu empreendimento.",
            //     "link": {
            //         "label": "Anunciar meu imóvel",
            //         "url": "#"
            //     }
            // },
        ]
    }
  },
  computed: {

    slideSettings() {
      let settings = {
        "dots": true,
        "arrows": true,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1
      }

      if (this.isMobile) {
        settings['slidesToShow'] = 1
        settings['dots'] = true
      }

      return settings
    },

  },
  methods: {

  },

  
  mounted() {

  },

  watch:{
  }

};
</script>