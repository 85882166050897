import MainService from './MainService'
class ContactService extends MainService {

    constructor() {
        super()
        this.api_name = "ApolarNew"
        this.api_path = "contact"
    }

    sendPropertyContact = async(data) => {
        data['sent_at'] = new Date()
        return this.doRequest("post",`property`, data)
    }

    sendGenericContact = async(data) => {
        return this.doRequest("post",`generic`, data)
    }

}

export default new ContactService();