<template>
    <div class="apolar-menu-options">
        <i class="fa fa-caret-up" aria-hidden="true"></i>
        <ul>
            <li v-for="menu,key in menu_options" v-bind:key="key">
                <a :href="menu.link">{{menu.label}}</a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
        menu_options: [
            {"link":"https://www.apolar.com.br/comprar", "label":"Vendas"},
            {"link":"https://www.apolar.com.br/alugar", "label":"Locação"},
            {"link":"/lancamentos", "label":"Lançamentos"},
            {"link":"https://procura.apolar.com.br/", "label":"Apolar Procura"},
            {"link":"https://www.apolar.com.br/consorcio/", "label":"Consórcio"},
            {"link":"https://www.apolar.com.br/triplea", "label":"Triple A"},
            {"link":"https://www.apolar.com.br/temporada", "label":"Temporada"},
            {"link":"https://www.apolar.com.br/feirao-apolar", "label":"Feirão Apolar"},
            {"link":"https://www.apolar.com.br/franquias/", "label":"Lojas Apolar"},
            {"link":"https://www.apolar.com.br/invista", "label":"Para Investidores"},
            {"link":"https://www.apolar.com.br/b4b", "label":"Build for Business"},
            {"link":"https://shortstay.apolar.com.br/", "label":"Apolar Short Stay"},
            {"link":"https://franqueado.apolar.com.br", "label":"Seja um Franqueado"},
            {"link":"https://opsganhei.com.br/", "label":"Indique"},
            {"link":"https://www.apolar.com.br/anuncie", "label":"Anuncie"}
        ]
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style>
</style>