<template>
    <div class="property-carousel-component" :id="getElementId">
        <div class="row">
          <div class="col-md-8 col-sm-12 col-xs-12">
            <h2 class="title">
              <slot name="title">Titulo do carousel</slot>
            </h2>
            <div class="subtitle">
              <slot name="subtitle"></slot>
            </div>
          </div>
          <div v-if="link_label" class="col-4 visit-page-area">
            <a target="_blank" :href="link_url" class="visit-page">{{link_label}}</a>
          </div>
        </div>

        <div class="row">
          <div class="col-12 property-list-area">

            <carousel class="property-carousel" :spacePadding="30" :navigationEnabled="true" :perPageCustom="[[300, 1],[600, 2],[1000, 3]]">
              <slide  v-for="property, pkey in properties"  v-bind:key="`prop_${pkey}`">
                <Property 
                  :property="property"
                  :preview_images="preview_images"
                />
              </slide>
            </carousel>

          </div>
        </div>

        <div v-if="hasFooterSlot" class="row">
          <div class="col-md-12">
              <slot name="footer"></slot>
          </div>
        </div>

    </div>
</template>
<script>
import Vue from 'vue'
import "@/assets/scss/custom/principal/components/_properties_carousel.scss"
import { Carousel, Slide } from "vue-carousel";
import Property from "./property.vue"
import PropertiesService from "../../../../services/PropertiesService";

export default {
  components: {
    Carousel,
    Slide,
    Property
  },
  props: {
    link_label: {
      type: String,
      default() {
        return "Visitar todos os imóveis da pagina"
      }
    },
    link_url: {
      type: String,
      default() {
        return "#"
      }
    },
    sub_business: {
      type: String,
      default() {
        return "feirao-apolar"
      }
    },
    pre_loaded_properties: {
      type: Array,
      default() {
        return []
      }
    },
    preview_images: {
      type: Number,
      default() {
        return 1
      }
    }
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    },

    getElementId() {
      if (!this.element_id){
        this.element_id = `carousel_${Math.random().toString(36).substr(2, 9)}`
      }
      return this.element_id
    }
  },
  data() {
    return {
      properties: [],
      properties_loaded: false,
      element_id: null
    };
  },
  methods: {

    load_properties() {

      if ( this.properties_loaded) {
        return false
      }

      if ( this.pre_loaded_properties.length > 0) {
        Vue.set(this, "properties", this.pre_loaded_properties)
        return false
      }

      if (!this.sub_business) {
        Vue.set(this, "properties", [])
        return false
      }

      let filters = {
        "sub_business": this.sub_business,
        "size": 12,
        "fields": ['IsFeiraoApolar','nomeEvento','tipo','transacao','finalidade','cidade','bairro','referencia','Quartos','condominio','garagem','dormitorios','areaterreno','area_total','banheiro','ValorAnterior','valor_considerado','situacao','FimPromocao','foto_principal','endereco','linksite','Selo','finalidade','popup_fotos', 'idtipomoeda']
      }

      PropertiesService.customSearch(filters, this.data_index)
      .then(
        response => {
            Vue.set(this, "properties_loaded", true)
            var properties = response.data
            Vue.set(this, "properties", properties)
        }
      )

    }

  },
  mounted() {
    //this.load_properties()
    //check if property carousel component is visible in dom

    // Create a new Intersection Observer
    const observer = new IntersectionObserver(entries => {
        if ( this.properties_loaded ) {
          return false
        }
        entries.forEach(entry => {
            // Check if the target element is intersecting with the root
            if (entry.isIntersecting) {
                this.load_properties()
                this.properties_loaded = true
                //console.log('Element is visible');
            } else {
                //console.log('Element is not visible');
            }
        });
    });

    // Select the target element
    const targetElement = document.getElementById(this.getElementId);

    // Start observing the target element
    observer.observe(targetElement);

  },
  watch:{
    pre_loaded_properties: function(value) {
      if ( value ) {
        Vue.set(this, "properties", value)
      }
    }
  }
};
</script>
<style>
</style>
