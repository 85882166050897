<template>
    <header class="header-global header-principal" :class="getHeaderClass">
        <base-nav class="navbar-main" type="" expand>
            <router-link slot="brand" class="navbar-brand" to="/">
                <img width="93" height="59" :src="apolar_logo" alt="Apolar Imóveis">
            </router-link>

            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
                <li 
                    v-for="menu,idx in menu_disposition" 
                    v-bind:key="`menu-${idx}`"
                    :class="typeof getMenu(menu).submenu !='undefined' ? 'has-submenu': ''"
                >
                    <a  target="_blank" :class="getMenu(menu).class" :href="getMenu(menu).link" 
                        @click.prevent="handleClick(getMenu(menu))">
                        {{ __(getMenu(menu).label) }}
                    </a>
                    
                    <ul v-if="getMenu(menu).submenu && getMenu(menu).submenu.length > 0" class="sub-menu">
                        <li 
                            v-for="sub,subidx in handle_submenu(menu, getMenu(menu).submenu)"
                            v-bind:key="`submenu-${subidx}`"
                        >
                            <a  target="_blank" :href="sub.link">{{ __(sub.label) }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </base-nav>
    </header>
</template>
<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import BaseNav from "@/components/BaseNav";

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    BaseNav
  },
  computed: {
      getHeaderClass() {
          return `header_${this.$route.name}`
      },
      apolar_logo() {
        if ( this.lang == 'es') {
            return require('@/assets/img/principal-logo-header-es.webp')
        }
        return require('@/assets/img/logo-apolar-imoveis-high.webp')
      }
  },
  props:{
    filter_header_opts: Array
  },
  data() {
    return {
        menu_disposition: [],
        menu_mobile_disposition: ['Indicar', 'Como Funciona', 'Perguntas Frequentes','Quero indicar', 'Minha Conta'],
        menu_desktop_disposition:
            ['Indicar', 'Como Funciona', 'Perguntas Frequentes','Quero indicar', 'Minha Conta'],
        menus: [
            {
                "link": "https://indica.apolar.net/?sign_user=yes",
                "label": "Indicar",
                "class": "anunciar-imoveis",
                "submenu": []
            },
            {
                "link": "",
                "label": "Como Funciona",
                "class": "anunciar-imoveis",
                "scroll": true,
                "ref":"como-funciona",
                "submenu": []
            },
            {
                "link": "",
                "label": "Perguntas Frequentes",
                "class": "anunciar-imoveis",
                "scroll": true,
                "ref":"perguntas-frequentes",
                "submenu": []
            },
            {
                "link": "https://indica.apolar.net/?sign_user=yes",
                "label": "Quero indicar",
                "class": "special-button seja-franqueado"
            },
            {
                "link": "https://indica.apolar.net/",
                "label": "Minha Conta",
                "class": "special-button area-cliente"
            }
        ],
        filter_header:[]
    };
  },
  methods: {
    handleClick(menu) {
      if (menu.scroll) {
        var sectionEle = document.querySelector(`section[data-ref='${menu.ref}']`);
        if (sectionEle) {
        window.scrollTo({ top: sectionEle.offsetTop, behavior: 'smooth' });
        } else {
        console.error('Element not found');
        }
      } else {
        window.open(menu.link, '_blank');
      }
    },
    setMenuLayout() {
        this.menu_disposition = this.menu_desktop_disposition
        if (this.isMobile) {
             this.menu_disposition = this.menu_mobile_disposition
        }

        if (this.lang == 'es') {
            this.menu_disposition = ['Comprar','Alugar','Anunciar Imóveis', 'Seja um Franqueado']
        }
        if (this.filter_header_opts.length > 0){
            this.menu_disposition = this.filter_header_opts.concat(['Seja um Franqueado', 'Área Cliente'])
        }
    },

    getMenu(menu_name) {
        let find_menu = this.menus.filter(menu => menu.label == menu_name)
        if ( find_menu.length > 0 ) {
            var menu = find_menu[0]
            return menu
        }
        return null
    },

    handle_submenu(menu_name, submenu) {
        
        if ( this.lang == 'es' ) {
            var exclude_submenu = null
            switch (menu_name) {
                case "Comprar":
                case "Produtos Apolar":
                    exclude_submenu = ['Lançamentos', 'Triple A']
                    break;

                case "Alugar":
                    exclude_submenu = ['ShortStay', 'Temporada', 'Ops! Aluguei']
                    break;
            
                default:
                    break;
            }

            if (exclude_submenu) {
                return submenu.filter(sbm => exclude_submenu.indexOf(sbm['label']) == -1 )
            }
        }

        return submenu

    }

  },
  mounted() {
     this.setMenuLayout()
  }
};
</script>
<style>
</style>
