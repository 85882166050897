<template lang="">
  <div class="indique-como-funciona">
    <div class="indique-como-funciona-title">
      <h2><b>COMO FUNCIONA</b></h2>
    </div>
    <div class="como-funciona-box">
      <div class="row">
        <div class="position-device-div" @mouseover="clickedPiggy=1" @mouseleave="clickedPiggy=2">
          <div class="indique-device">
            <div class="indique-device-div">
              <img :src="require(`@/assets/img/indique/indique_device.webp`)" />
              <div class="indique-device-div-header">
                <img :src="require(`@/assets/img/indique/header-logos.webp`)">
              </div>
              <div class="messages">
                <div>
                  <div class="indique-device-piggy-bank message-item" :class="{'flip-2-hor-top-bck front' : clickedPiggy == 1, 'flip-2-hor-bottom-1' : clickedPiggy == 2}">
                  </div>
                  <div class="indique-device-div-content message-item" :class="{'flip-2-hor-bottom-1' : clickedPiggy == 1, 'flip-2-hor-top-bck front' : clickedPiggy == 2}">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cards">
          <div class="card-component">
            <div class="card-content">
              <div>
                <div class="card-text-div">
                  <div class="img-div">
                    <img :src="require(`@/assets/img/indique/share-icon.webp`)" alt="Icon" class="icon-share">
                    <span class="icon-text">INDICAÇÃO POR LINK</span>
                  </div>
                </div>
                <h2>Compartilhe seu link</h2>
                <p>Acessando sua conta você pode compartilhar seu link de indicação. O proprietário irá cadastrar o imóvel utilizando seu link e assim que o imóvel for publicado, você ganha!!</p>
              </div>
            </div>
            <div class="indique-card-footer">
              <a href="https://indica.apolar.net/?sign_user=yes" target="_blank">Quero indicar<img :src="require(`@/assets/img/indique/small-arrow-right.webp`)" alt=""></a>
            </div>
          </div>
          <div class="card-component">
            <div class="card-content">
              <div>
                <div class="card-text-div">
                  <div class="img-div">
                    <img :src="require(`@/assets/img/indique/home-icon.webp`)" alt="Icon" class="icon-home">
                    <span class="icon-text">INDICAÇÃO FÁCIL</span>
                  </div>
                </div>
                <h2>Cadastre o <br>contato</h2>
                <p>Basta acessar nosso site, preencher o cadastro com os dados de contato do proprietário, o endereço completo do imóvel e pronto!</p>
              </div>
            </div>
            <div class="indique-card-footer">
              <a href="https://indica.apolar.net/?sign_user=yes" target="_blank">Quero indicar<img :src="require(`@/assets/img/indique/small-arrow-right.webp`)" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      clickedPiggy: 0,
      cardClicked1: false,
      cardClicked2: false,
    }
  },
  computed: {
  },
  methods: {
    definePiggyState(e) {}
  },

  mounted() {
  },

  watch: {}

};
</script>
<style lang="">

</style>