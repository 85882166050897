<template>
    
    <div  target="_blank" :href="property_url" class="property-component" :class="shortstayClass + temporadaClass">
        
        <div class="image-are">
            <img width="1" height="1" :src="principal_photo" :alt="property_title">
        </div>
        <div class="info-area">
            <div class="description-and-address">
                <h3>{{property_title}}</h3>
                <div class="address">{{property_address}}</div>
                <div class="condominium">{{__('Condomínio')}}: {{property_condominium}}</div>
                <div class="ref">Ref. {{property.referencia}}</div>
            </div>

            <div class="highlights-and-prices">
                <div class="property-highlights">
                    <ul class="highlights-options">
                        <li v-for="item,key in highlights" v-bind:key="`high_${key}`" >
                        <img :src="item.img" alt="">
                        <div class="value">{{item.value}}</div>
                        <div class="label">{{item.label}}</div>
                        </li>
                    </ul>
                </div>
                <div class="prices-and-more">
                    <div class="business-type">{{property_business_type}}</div>
                    <div class="property-price">R$ {{current_price}}</div>
                    <div v-if="vlrcondominio > 0" class="condominium-price">{{__('Condomínio')}} R$ {{condominium_price}}</div>
                    <a :href="get_property_url(property)" target="_blank" class="know-more">{{__('Saber Mais')}}</a>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import Vue from 'vue'
import { Carousel, Slide } from "vue-carousel";

import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


export default {
  components: {
    Carousel,
    Slide,
    VueSlickCarousel
  },
  props: {
    property: {
      type: Object,
      default() {
        return {}
      }
    },
    preview_images: {
        type: Number,
        default() {
            return 1
        }
    }
  },
  computed: {

    property_title() {
        var title = ""

        if (Object.keys(this.property).length > 0) {
            title = `${this.property.tipo} em Condomínio no ${this.property.bairro_list[0]}, ${this.area_info}`
        }

        return title
    },

    property_address() {
        
        if (Object.keys(this.property).length > 0) {
            return `${this.property.endereco}, ${this.property.numero}, ${this.property.bairro} - ${this.property.cidade}`
        }
        return ''
    },

    property_condominium() {
        if (Object.keys(this.property).length > 0) {
            return this.property.condominio.toLowerCase()
        }
        return ""
    },

    property_business_type() {
        if (Object.keys(this.property).length > 0) {
            return this.property.transacao == 'Locacao' ? 'Locação' : 'Vendas'
        }
        return ""
    },

    areaTypes() {
        return ['Terreno', 'Sítio', 'Chácara', 'Área', 'Área Condominio Fechado'].indexOf(this.property.tipo) != -1 ? true : false
    },

    shortstayClass() {
        if (typeof this.property.nomeEvento != "undefined" && this.property.nomeEvento.indexOf('shortstay') != -1) {
            return 'shortstay-property'
        } else {
            return ''
        }
    },

    temporadaClass() {
        if (typeof this.property.nomeEvento != "undefined" && this.property.nomeEvento.indexOf('temporada') != -1) {
            return 'temporada-property'
        } else {
            return ''
        }
    },


    parking_info() {
        let parking = parseInt(this.property.garagem)
        if ( parking <= 1 ) {
            return `${parking} vaga`
        } else {
            return `${parking} vagas`
        }
    },

    bedrooms_info() {
        let bedrooms = parseInt(this.property.dormitorios)
        if ( bedrooms <= 1 ) {
            return `${bedrooms} quarto`
        } else {
            return `${bedrooms} quartos`
        }
    },

    area_info() {
        return this.areaTypes ? `${Math.trunc(this.property.areaterreno)} m²` : `${Math.trunc(this.property.area_total)} m²`
    },

    bathroom_info() {
        return `${Math.trunc(this.property.banheiro)}`
    },

    old_price() {
        if ( this.property.ValorAnterior && this.property.ValorAnterior > this.property.valor_considerado ) {
            return this.number_format(this.property.ValorAnterior, 2, ',', '.')
        }
        return null
    },

    current_price() {
        return this.number_format(this.property.valor_considerado, 2, ',', '.')
    },

    condominium_price() {
        if (Object.keys(this.property).length > 0) {
            return this.number_format(this.property.vlrcondominio, 2, ',', '.')
        }
        return 'R$ 0,00'
    },

    countdow() {
        let countdow = null

        if ( this.property.situacao.toLowerCase().indexOf('reservad') != -1 ) {
            return countdow
        }

        if ( 
                parseInt(this.property.IsFeiraoApolar) == 1 && 
                typeof this.property.FimPromocao != "undefined" && 
                typeof this.property.FimPromocao ) {

            let today = new Date()
            let end = new Date(this.property.FimPromocao)
            let diff_time = Math.abs(end - today);
            let diff_days = Math.ceil(diff_time / (1000 * 60 * 60 * 24));

            return diff_days <= 1 ? `${diff_days} dia` : `${diff_days} dias`

        }

        return countdow
    },

    reserved() {
        return this.property.situacao.toLowerCase().indexOf('reservad') != -1
    },

    photos() {
        let usage_photos = [this.principal_photo]
        let photos = this.property.popup_fotos.Foto.map(f => f['URLArquivo'][0])
        photos = photos.filter(f => f != this.property.foto_principal)
        if (this.preview_images >  1) {
            usage_photos = usage_photos.concat(photos.slice(0, this.preview_images-1))
        }
        return usage_photos
    },

    principal_photo() {
        return this.property.foto_principal.indexOf('semfoto.jpg') != -1 ?  require("@/assets/img/no-photo.webp") : this.property.foto_principal
    },

    address_info() {
        let num = 30
        return this.property.endereco.length > num ? this.property.endereco.slice(0, num) + "..."  : this.property.endereco
    },

    highlights() {

      let dormitorios = 0
      let total_unidades = 0
      let area_privativa = '- m²'
      let vagas = 0
      let suites = 0


      if (Object.keys(this.property).length > 0) {
        dormitorios = this.property.dormitorios
        vagas = this.property.garagem
        suites = this.property.suites
        area_privativa = `${this.property.area_total} m²`
      }

      var list = [
        {
          'img': require("@/assets/img/condominio/cama.webp"),
          'label': dormitorios <= 1 ? this.__('Quarto') : this.__('Quartos'),
          'value': dormitorios
        },
        // {
        //   'img': require("@/assets/img/condominio/predio.webp"),
        //   'label': this.__('Disponível'),
        //   'value': total_unidades
        // },
        {
          'img': require("@/assets/img/condominio/metragem.webp"),
          'label': this.__('Privat.'),
          'value': area_privativa
        },
        {
          'img': require("@/assets/img/condominio/carro.webp"),
          'label': vagas <= 1 ? this.__('Vaga') : this.__('Vagas'),
          'value': vagas
        },
        {
          'img': require("@/assets/img/condominio/banheira.webp"),
          'label': suites <= 1 ? this.__('Suíte') : this.__('Suítes'),
          'value': suites
        }
      ]

      return list

    }
  },
  data() {
    return {
    };
  },
  methods: {
    openProperty() {
        
        if ( this.property.transacao == "Lancamento" ) {

            if ( typeof this.property['linksite'] != "undefined" && this.property['linksite'] ) {
                window.open(this.property['linksite'], '_blank').focus()
                return false
            }

            let slug = this.slugify(`${this.property.cidade}-${this.property.bairro}-${this.property.condominio}-${this.property.referencia}`)
            this.$router.push({
                name: 'property-details',
                params: {
                    city: this.slugify(this.property.cidade),
                    district: this.slugify(this.property.bairro),
                    slug: slug,
                }
            })

        } else {

            let slug = this.slugify(`${this.property.transacao}-${this.property.finalidade}-${this.property.tipo}-${this.property.cidade}-${this.property.bairro}-${this.property.referencia}`)
            
            this.$router.push({
                name: 'principal-property-details',
                params: {
                    city: this.slugify(this.property.cidade),
                    district: this.slugify(this.property.bairro),
                    slug: slug,
                }
            })


        }

    },
    slugify(text) {
      return text
        .toString()                           // Cast to string (optional)
        .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                                  // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-')            // Replace spaces with -
        .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    }
  },
  mounted() {
  }
};
</script>
<style>
</style>
