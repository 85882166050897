<template>
  <article
    class="immobile-home col-md-4"
    :class="layout"
    :style="getBackgroundImage()"
    v-on:click="openProperty()"
  >
    <div class="backdrop-info">
      <div class="backdrop-info-city">{{ this.property.cidade }}</div>
      <div class="backdrop-info-condominium">
        {{ this.property.condominio }} <span class="line"></span>
      </div>
    </div>
    <div class="main-info">
      <div v-show="layout != 'exposed'" class="main-info-more">Ver mais</div>
      <div v-show="layout == 'exposed'" class="main-info-city">
        {{ this.property.cidade }}
      </div>
      <div class="main-info-condominium">{{ shorName }}</div>
      <div class="main-info-address">
        {{ this.property.cidade }} | {{ this.property.bairro }}
      </div>
      
      <ul class="features">
        <li>
          <img src="@/assets/img/lancamentos/icon-imo-rule.webp" alt="Metragem">
          {{footageInfo}}
        </li>
        <li v-if="this.property.dormitorios > 0" >
          <img src="@/assets/img/lancamentos/icon-imo-bed.webp" alt="Dormitórios">
          {{dormitoriosInfo}}
        </li>
        <li v-if="this.property.garagem > 0" >
          <img src="@/assets/img/lancamentos/icon-imo-garage.webp" alt="Vagas">
          {{garagemInfo}}
        </li>
        <li v-if="this.property.banheiro > 0" >
          <img src="@/assets/img/lancamentos/icon-imo-bath.webp" alt="Banheiros">
          {{banheiroInfo}}
        </li>
      </ul>
    </div>
  </article>
</template>
<script>
import jQuery from "jquery";

export default {
  components: {},
  props: ["property", "layout"],
  data() {
    return {};
  },
  computed: {
    shorName: function() {
      return this.truncateString(this.property.condominio.toLowerCase(), 25)
    },
    shortDescription: function() {
      return this.property.descricao.toLowerCase().substring(0, 70) + '...'
    },
    footageInfo: function() {

      if ( typeof this.property.TamDeAte == "number" ) {
        return `${this.property.TamDeAte}m²`
      }

      let [de, ate] = this.property.TamDeAte.split("até")
      return !ate || de.toString().trim() == ate.toString().trim() ? `${de}m²` : `${de} a ${ate}m²`
    },

    dormitoriosInfo: function() {
      return this.property.dormitorios > 1 ? `${this.property.dormitorios} quartos` : `${this.property.dormitorios} quarto`
    },

    garagemInfo: function() {
      return this.property.garagem > 1 ? `${this.property.garagem} vagas` : `${this.property.garagem} vaga`
    },

    banheiroInfo: function() {
      return this.property.banheiro > 1 ? `${this.property.banheiro} banheiros` : `${this.property.banheiro} banheiro`
    }
  },
  methods: {
    getBackgroundImage() {
      let foto = this.property.popup_fotos.Foto[0].URLArquivo[0]
      return `background-image: url('${foto}')`;
    },
    initHoverTransaction() {
      if (this.layout != "exposed") {
        jQuery(this.$el).hover(
          () => {
            jQuery(this.$el).find(".backdrop-info").addClass('visible')
            jQuery(this.$el).find(".backdrop-info .backdrop-info-city").fadeOut("fast");
            jQuery(this.$el).find(".backdrop-info .backdrop-info-condominium").fadeOut("fast");
            jQuery(this.$el).find(".main-info").fadeIn("fast");
          },
          () => {
            jQuery(this.$el).find(".main-info").fadeOut("fast");

            jQuery(this.$el).find(".backdrop-info").removeClass('visible')
            jQuery(this.$el).find(".backdrop-info .backdrop-info-city").fadeIn("fast");
            jQuery(this.$el).find(".backdrop-info .backdrop-info-condominium").fadeIn("fast");
          }
        );
      }
    },
    slugify(text) {
      return text
        .toString()                           // Cast to string (optional)
        .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                                  // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-')            // Replace spaces with -
        .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    },
    openProperty() {

        if ( typeof this.property['linksite'] != "undefined" && this.property['linksite'] ) {
          window.open(this.property['linksite'], '_blank').focus()
          return false
        }

        let slug = this.slugify(`${this.property.cidade}-${this.property.bairro}-${this.property.condominio}-${this.property.referencia}`)
        this.$router.push({
            name: 'property-details',
            params: {
                city: this.slugify(this.property.cidade),
                district: this.slugify(this.property.bairro),
                slug: slug,
            }
        })
    },

    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + ".";
      } else {
        return str;
      }
    }
  },
  mounted() {
    this.initHoverTransaction();
  },
};
</script>
<style>
</style>