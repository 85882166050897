<template>
  <section class="similar-properties-main-component">
    <PropertiesCarousel
      :link_label="''"
      :link_url="''"
      :sub_business="''"
      :pre_loaded_properties="similar_properties"
      :preview_images="3"
    >
      <template v-slot:title>{{__('Imóveis similares')}}</template>
      <template v-slot:subtitle></template>
      <template v-slot:footer></template>
    </PropertiesCarousel>
  </section>
</template>
<script>
import jQuery from "jquery";
import PropertiesService from "../../../services/PropertiesService";
import PropertiesCarousel from "../../components/apolar/principal/properties-carousel.vue"

export default {
  components: {
    PropertiesCarousel
  },
  props: ["property", "layout"],
  data() {
    return {
      similar_properties:[]
    };
  },
  computed: {
    
  },
  methods: {
    loadSimilar() {
        PropertiesService.similar(this.property,  this.data_index)
        .then((response)=>{
            console.log("similar: ", response)
            this.similar_properties = response.data
            this.$emit('properties_count', this.similar_properties.length)
        })
    }
  },
  mounted() {
    
  },
  watch:{
    property: function(value) {

      if ( value ) {
        this.loadSimilar()
      }

    }
  }
};
</script>
<style>
</style>